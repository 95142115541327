import { Outlet, useLocation } from "react-router";
import React from "react";
import Topbar from "../Navigation/Topbar/Topbar";
import Sidebar from "../Navigation/Sidebar/Sidebar";
import { Box } from "@mui/material";
import { MENU_FULL_OPTIONS, MENU_NEW_USER } from "../../data";

function Layout() {
  const location = useLocation();

  const barrerToken = sessionStorage.getItem("token_key");
  const allowedRoutes = ["/login", "/forget-password"];

  if (!allowedRoutes.includes(location.pathname)) {
    if (!barrerToken) {
      window.location.href = "/login";
    }
  }

  const menuItems =
    location.pathname === "/" ? MENU_NEW_USER : MENU_FULL_OPTIONS;

  return allowedRoutes.includes(location.pathname) ? (
    <Outlet />
  ) : (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.foreground,
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        display: "flex",
      })}
    >
      <Sidebar menuItems={menuItems} />
      <Box
        sx={{
          width: location.pathname === "/" ? "100%" : "90%",
          // width: location.pathname === "/presentations" ? "94%" : "100%",
          flexDirection: "column",

          ...(location.pathname === "/presentations" && {
            width: "94%",
            "@media (max-width: 1260px)": {
              pr: "20px",
            },
          }),
        }}
        display="flex"
      >
        <Topbar />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            height: "calc(98vh - 75px)",
            overflowY: "auto",
            borderRadius: "20px 0 0 20px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          })}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
