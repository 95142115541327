import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import BaseModal from "../Global/Modal";
import DragAndDrop from "../Global/DragAndDrop";

function UploadSection({ state, setState, title }) {
  const { t } = useTranslation();
  const [tempFiles, settempFiles] = useState([]);

  const [openUpload, setOpenUpload] = useState(false);

  const handleFile = (e) => {
    const files = e.target.files;
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      newFiles.push({
        name: files[i].name,
        url: files[i],
      });
    }
    settempFiles([...tempFiles, ...newFiles]);
  };

  const handleDelete = (index) => {
    const newState = [...state];
    newState.splice(index, 1);
    setState(newState);
  };

  return (
    <Box
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.common.border}`,
        pt: "20px",
        mt: "20px",
      }}
    >
      <Typography variant="body">{t(title)}</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={(theme) => ({
          background: theme.palette.background.lightBack,
          p: "10px",
          borderRadius: "20px",
          mt: "17px",
        })}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {state?.length > 0 &&
            state?.map((item, index) => {
              return (
                <Chip
                  onDelete={() => handleDelete(index)}
                  label={item.name}
                  key={index}
                />
              );
            })}
        </Box>
        <IconButton color="primary" onClick={() => setOpenUpload(true)}>
          <CloudUploadIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
        {state?.map((item, index) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              key={index}
            >
              <IconButton color="primary">
                <ArticleIcon />
              </IconButton>
              <Typography variant="smallBodyBold">{item.name}</Typography>
              <IconButton onClick={() => handleDelete(index)}>
                <CloseIcon />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
      <BaseModal
        open={openUpload}
        showSaveButton
        title={`Upload New ${title}`}
        handleClose={() => setOpenUpload(false)}
        onSave={() => {
          setState([...state, ...tempFiles]);
          setOpenUpload(false);
          settempFiles([]);
        }}
      >
        <DragAndDrop
          allowMultiple
          onChangeFile={handleFile}
          sx={{
            margin: "auto",
          }}
        />
      </BaseModal>
    </Box>
  );
}

export default UploadSection;
