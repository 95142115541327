import http from "../http-common";

let tokensData = JSON.parse(sessionStorage.getItem("token"));

class UploadFilesService {
  upload(path,file, onUploadProgress) {
    let formData = new FormData();
    var url = process.env.REACT_APP_API_PROFILE_SERVICE_URL+'/api/'+ path;
    formData.append("file", file);
    console.log(url);
    return http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': " Bearer " + tokensData.token,
      },
      onUploadProgress,
    });
  }

  // getFiles() {
  //   return http.get("/files");
  // }
}

export default new UploadFilesService();
