import React, { useReducer, useEffect, useState, useRef } from "react";
import BaseModal from "../../Global/Modal";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import profileService from "../../../services/myprofile";
import { useNavigate } from "react-router-dom";
import {
  errorHelperText,
  hasError,
  validateCalculate,
  validateStringArray,
} from "../../../utils/validation";
import dayjs from "dayjs";
import { useSnackbar } from "../../Global/WithSnackbar";
import jwtInterceptor, {jwtLeave } from "../../../services/interceptors";
import { useTranslation } from "react-i18next";

const newLeaveItem = {
  employeeDetailId: 0,
  leaveTypeId: 0,
  totalDays: 0,
  startDate: "2023-07-20T01:41:26.427Z",
  endDate: "2023-07-20T01:41:26.427Z",
  isHalfDay: true,
  isIncludingWeekand: true,
  employeeComments: "string",
  lineManagerId: 0,
};

function LeaveRequest({ open, onClose, onSave }) {
  const LeaveTypes = [];
  const initialized = useRef(false);
  const [leavesListData, setleavesListDataState] = useState([]);
  const [newLeaveData, setnewLeaveDataState] = useState(newLeaveItem);
  const [managersListData, setmangersListDataState] = useState([]);
  const [save, setSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [dateError, setDateError] = useState("");
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "leaveType":
          return { ...prevState, leaveTypeId: action.value };
        case "startDate":
          let sDate =
            action.value.$y +
            "-" +
            ("0" + (action.value.$M+1)).slice(-2) +
            "-" +
            ("0" + action.value.$D).slice(-2);
          return { ...prevState, startDate: sDate };
        case "endDate":
          let eDate =
            action.value.$y +
            "-" +
            ("0" + (action.value.$M+1)).slice(-2) +
            "-" +
            ("0" + action.value.$D).slice(-2);
          return { ...prevState, endDate: eDate };
        case "halfDay":
          return {
            ...prevState,
            isHalfDay: action.value,
          };
        case "weekends":
          return {
            ...prevState,
            isIncludingWeekand: action.value,
          };
        case "totalDays":
          return {
            ...prevState,
            totalDays: action.value,
          };
        case "comments":
          return {
            ...prevState,
            employeeComments: action.value,
          };
        case "manager":
          return {
            ...prevState,
            lineManagerId: action.value,
          };

        default:
          throw new Error();
      }
    },
    {
      employeeDetailId: empId,
      leaveTypeId: 0,
      totalDays: 0,
      startDate: "",
      endDate: "",
      isHalfDay: false,
      isIncludingWeekand: false,
      employeeComments: "string",
      lineManagerId: 29,
    }
  );

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      GetLeavesConfigurationData();
      GetMangersListData();
    }
  }, []);

  const GetLeavesConfigurationData = async () => {
    let url =
      "api/EmployeeLeave/GetLeaveTypeListByGenderRestriction?EmployeeDetailId=" +
      empId;  
      setLoading(true);
    jwtLeave
    .get(url)
    .then((response) => {
      for (var x of response.data) {
        let item = {
          leaveTypeId: x.leaveTypeId,
          leaveType: x.leaveType,
        };
        LeaveTypes.push(item);
      }
      setleavesListDataState(LeaveTypes);
    })
    .catch((err) => {
      showMessage(err.message, "error");
    })
    .finally(() => setLoading(false));

   
  };

 

  const checkIsEndDateIsAfterStartDate = () => {
    const { startDate, endDate } = state;
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError("End date should be after start date or same as start date");
      return false;
    }
    setDateError("");
    return true;
  };

  const GetCaluclateLeavesData = async () => {
    const { leaveTypeId, startDate, endDate } = state;

    const errors = validateCalculate({ leaveTypeId, startDate, endDate });

    if (errors.length > 0 || !checkIsEndDateIsAfterStartDate()) {
      setErrors(errors);
    } else {
      setErrors([]);
      let calculateLeaves = {
        employeeDetailId: state.employeeDetailId,
        leaveTypeId: state.leaveTypeId,
        startDate: state.startDate,
        endDate: state.endDate,
        isHalfDay: state.isHalfDay,
        isIncludingWeekand: state.isIncludingWeekand,
      };

      let url = "api/EmployeeLeave/GetCalculateDays";
      
      jwtLeave
      .post(url,calculateLeaves)
      .then((response) => {
        if (response && response.data.statusCode === 200) {
          if (response.data.data.leaveCount <= 0)
            showMessage(response.data.message, "error");
          else {
            dispatch({
              type: "totalDays",
              value: response.data.data.leaveCount ? response.data.data.leaveCount : 0,
            });
            setSave(true);
          }
        } else {          
          showMessage(response.data.message, "error");
        }
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));      
    }
  };

  const createNewLeave = async () => {
    setLoading(true);
    let url = "api/EmployeeLeave/CreateEmployeeLeave";
    
    jwtLeave
      .post(url,state)
      .then((res) => {
        onClose();
        setSave(false);
        onSave();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));

  };

  const GetMangersListData = async () => {

    let url = "api/HrEmployeeDetail/GetManagerList";    
    let mangers = [];
    jwtInterceptor.get(url).then((response) => {
      for (var x of response.data) {
        let item = {
          managerId: x.employeeDetailId,
          managerName: x.userName,
        };
        mangers.push(item);
      }
      setmangersListDataState(mangers);
    })
    .catch((err) => {
      showMessage(err.message, "error");
    });       
  };

  const onSubmit = () => {
    createNewLeave();
  };

  const { t } = useTranslation();

  return (
    <BaseModal
      open={open}
      handleClose={onClose}
      onSave={onSubmit}
      showSaveButton={save}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="SmallBody">{t("Leave Type")}</Typography>
          <Select
            variant="outlined"
            placeholder={t("Select leave type")}
            value={state.leaveType}
            onChange={(e) =>
              dispatch({ type: "leaveType", value: e.target.value })
            }
            error={hasError("leaveTypeId", errors)}
          >
            {leavesListData.map((item, i) => (
              <MenuItem value={item.leaveTypeId} key={i}>
                {t(item.leaveType)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="SmallBody">{t("Leave calculator")}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" mt={2}>
              {state.totalDays}
            </Typography>
            <Button
              variant="outlined"
              onClick={GetCaluclateLeavesData}
              sx={{ padding: "2px 20px", width: "fit-content" }}
            >
              {t("calculate")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.halfDay}
                onChange={(e) =>
                  dispatch({ type: "halfDay", value: e.target.checked })
                }
              />
            }
            label={t("Half Day")}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.fullDay}
                onChange={(e) =>
                  dispatch({ type: "weekends", value: e.target.checked })
                }
              />
            }
            label={t("Include Weekends")}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="SmallBody">{t("Line Manager")}</Typography>
          <Select
            variant="outlined"
            placeholder={t("Select manager")}
            value={state.lineManager}
            onChange={(e) =>
              dispatch({ type: "manager", value: e.target.value })
            }
          >
            <MenuItem disabled value="">
              <em>{t("Manager")}</em>
            </MenuItem>
            {managersListData.map((item, i) => (
              <MenuItem value={item.managerId} key={i}>
                {t(item.managerName)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="SmallBody">{t("Starting Date")}</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) => dispatch({ type: "startDate", value: e })}
              format="DD/MM/YYYY"
            />
            {hasError("startDate", errors) &&
              errorHelperText("Start Date is required")}
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="SmallBody">{t("End Date")}</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) => dispatch({ type: "endDate", value: e })}
              format="DD/MM/YYYY"
            />
            {hasError("endDate", errors) &&
              errorHelperText("End Date is required")}
            {dateError && errorHelperText(dateError)}
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Comments")}</Typography>
          <TextField
            variant="outlined"
            placeholder={t("input notes")}
            multiline
            rows={3}
            onChange={(e) =>
              dispatch({ type: "comments", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </BaseModal>
  );
}

export default LeaveRequest;
