import React, { useState } from "react";
import EnhancedTable from "../Global/Table";
import { Box, Button, Typography, alpha, Grid } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Circle } from "@mui/icons-material";
import { CircularChip } from "../Global/Chips";
import AddNewLeave from "./AddNewLeave";
import { jwtLeave } from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";
import DeleteModal from "../Global/DeleteModal";
import BankHolidaySetting from "./BankHolidaySetting";

const headCells = [
  {
    id: "Leave",
    label: "Leave",
  },
  {
    id: "Limitation",
    label: "Limitation",
  },
  {
    id: "Daysentitled",
    label: "Days entitled",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(Leave, Limitation, Daysentitled, id, onEdit, onDelete) {
  return {
    Leave,
    Limitation: Limitation ? Limitation : "N/A",
    Daysentitled: <CircularChip value={Daysentitled} />,
    Action: (
      <CellAction onEdit={() => onEdit(id)} onDelete={() => onDelete(id)} />
    ),
  };
}

function Status({ status }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <Circle color={status === "Active" ? "success" : "error"} />
      <Typography variant="smallBodyBold">{status}</Typography>
    </Box>
  );
}

function CellAction({ onEdit, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
        onClick={onEdit}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={onDelete}
      >
        <DeleteIcon />
      </Button>
      {/* <Button>
        <MoreVertIcon />
      </Button> */}
    </Box>
  );
}

function Assets() {
  const [open, setOpen] = useState({
    open: false,
    id: null,
  });
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const [leaveConfig, setLeaveConfig] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });

  const getLeaveConfig = async () => {
    setLoading(true);
    jwtLeave
      .get("api/LeaveConfiguration/GetAllLeaveConfiguration")
      .then((res) => {
        setLeaveConfig(res.data);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });
    setLoading(false);
  };

  const onEdit = (id) => {
    setOpen({
      open: true,
      id: id,
    });
  };

  const onDelete = (id) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };

  const updateLeaveConfig = async (data) =>
    jwtLeave
      .post("api/LeaveConfiguration/UpdateLeaveConfiguration", data)
      .then((res) => {
        showMessage("Leave configuration updated successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const createMewLeaveConfig = async (data) =>
    jwtLeave
      .post("api/LeaveConfiguration/CreateLeaveConfiguration", data)
      .then((res) => {
        showMessage("Leave configuration created successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const deleteLeaveConfig = async (id) =>
    jwtLeave
      .delete(
        `api/LeaveConfiguration/DeleteLeaveConfiguration?leaveTypeId=${id}`
      )
      .then((res) => {
        showMessage("Leave configuration deleted successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const onDeleteConfirm = async () => {
    await deleteLeaveConfig(deleteModal.id);
    getLeaveConfig();
    setDeleteModal({
      open: false,
      id: null,
    });
  };

  const onSave = async (data) => {
    if (data.leaveTypeId) {
      await updateLeaveConfig(data);
    } else {
      await createMewLeaveConfig(data);
    }

    getLeaveConfig();

    setOpen({
      open: false,
      id: null,
    });
  };

  React.useEffect(() => {
    getLeaveConfig();
  }, []);

  return (
    <>
      <Grid
        sx={(theme) => ({
          padding: "10px",
          margin: "10px",
          alignItems: "flex-start",
        })}
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={(theme) => ({
            borderRadius: "10px",
            border: `1px solid ${theme.palette.common.border}`,
            mt: 2,
          })}
        >
          <EnhancedTable
            head={headCells}
            rows={leaveConfig.map((item) =>
              createData(
                item.leaveType,
                item.genderRestriction,
                item.daysEntitled,
                item.leaveTypeId,
                onEdit,
                onDelete
              )
            )}
            isAddable={true}
            onAddClick={() =>
              setOpen({
                open: true,
                id: null,
              })
            }
            title="Leave Configuration"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BankHolidaySetting />
        </Grid>
      </Grid>
      <AddNewLeave
        open={open.open}
        handleClose={() => {
          setOpen({
            open: false,
            id: null,
          });
        }}
        handleSave={onSave}
        leave={leaveConfig.find((item) => item.leaveTypeId === open.id)}
      />
      <DeleteModal
        open={deleteModal.open}
        message={"Are you sure you want to delete this leave?"}
        title={"Delete leave"}
        onCancel={() => {
          setDeleteModal({
            open: false,
            id: null,
          });
        }}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

export default Assets;
