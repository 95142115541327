import React, { useEffect, useReducer, useRef, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import EditAndSave from "../../../Global/EditAndSave";
import DragAndDrop from "../../../Global/DragAndDrop";
import profileService from "../../../../services/myprofile";
import { validateBankDetails } from "../../../../utils/validation";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../../services/interceptors";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../../Global/WithSnackbar";

const intialBankDetails = {
  EmployeeDetailId: 0,
  bankName: null,
  location: null,
  bankCodeType: null,
  bankCodeCategory: null,
  bankCode: null,
  accountNumber: null,
  confirmAccountNumber: null,
  file: null,
};
const bankreducer = (state, action) => {
  switch (action.type) {
    case "bank":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "serviceData":
      return {
        ...state,
        ...action.value,
      };
    case "error":
      return {
        ...state,
        error: action.value,
      };
    case "reset":
      return {
        ...state,
        error: [],
      };
    default:
      return state;
  }
};

function BankAccount() {
  const navigate = useNavigate();
  const [preState, setPreState] = useState([]);
  //const [mybankDetails, setbankDetailsSate] = useState(intialBankDetails);
  const [mybankDetails, bankdispatch] = useReducer(
    bankreducer,
    intialBankDetails
  );
  const { showMessage } = useSnackbar();
  const [errors, setErrors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const initialized = useRef(false);

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  //
  const getBanAccountData = async () => {
    jwtInterceptor
      .get("api/EmployeeBankDetail/GetBankDetails?EmployeeDetailId=" + empId)
      .then((response) => {
        bankdispatch({
          type: "serviceData",
          field: "",
          value: response.data,
        });
      });
  };
  //

  const updateBanAccountData = async () => {
    const formData = new FormData();
    console.log(mybankDetails);
    formData.append("file", file);
    // formData.append("name", name); filename is not required at any of the place
    formData.append("BankName", mybankDetails.bankName);
    formData.append("Location", mybankDetails.location);
    formData.append("BankCode", mybankDetails.bankCode);
    formData.append("AccountNumber", mybankDetails.accountNumber);
    formData.append("ConfirmAccountNumber", mybankDetails.confirmAccountNumber);
    formData.append("EmployeeDetailId", empId);

    if (file) {
      let url = "api/EmployeeBankDetail/AddUpdateBankDetails";
      /*let response = await profileService.uploadBankDocumentRequest(
        url,
        formData,
        bearerToken
      );*/

      jwtInterceptor.post(url, formData).then((response) => {
        showMessage(response.data, "success");
      });
    }
  };

  const uploadBankDocument = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);

    if (file) {
      let url =
        "api/EmployeeBankDetail/UploadBankAttachment?EmployeeDetailId=" + empId;
      /* let response = await profileService.uploadBankDocumentRequest(
        url,
        formData,
        bearerToken
      );*/

      jwtInterceptor.post(url, formData).then((response) => {
        showMessage(response.data, "success");
      });
    }
  };

  const btn_update_click = () => {
    const errorsFound = validateBankDetails({
      bankName: mybankDetails.bankName,
      location: mybankDetails.location,
      bankCode: mybankDetails.bankCode,
      accountNumber: mybankDetails.accountNumber,
      confirmAccountNumber: mybankDetails.confirmAccountNumber,
    });

    if (file === null) {
      errorsFound.push("file");
    }

    if (errorsFound.length > 0) {
      setErrors(errorsFound);
      return;
    }

    setEdit(false);

    updateBanAccountData();
  };

  const inputChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    bankdispatch({
      type: "bank",
      field: name,
      value: val,
    });
  };

  const setFileInformation = (file) => {
    setFile(file);
    setName(file.name);
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getBanAccountData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const hasError = (field) => {
    return errors.includes(field);
  };

  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Typography variant="LargeBody" flex={1}>
          {t("Bank details")}
        </Typography>
        <EditAndSave
          edit={edit}
          setEdit={setEdit}
          onUpdate={() => {
            btn_update_click();
          }}
          onCancel={() => {
            bankdispatch({
              type: "reset",
              field: "",
              value: "",
            });
            setErrors([]);
          }}
          onSave={() => {
            setPreState(mybankDetails);
          }}
        />
      </Box>

      <Grid container>
        <Grid container sm={12} md={5.9} lg={5.8}>
          <Grid container mt="21px" gap="20px">
            <Grid item xs={12} sm={5.75}>
              <Typography variant="SmallBody">{t("Bank Name")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("Enter Bank Name")}
                name="bankName"
                onChange={inputChange}
                value={mybankDetails.bankName}
                error={hasError("bankName")}
                disabled={!edit}
                helperText={
                  hasError("bankName") ? t("Please enter bank name") : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.75}>
              <Typography variant="SmallBody">{t("Location")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("Enter Location")}
                name="location"
                onChange={inputChange}
                value={mybankDetails.location}
                error={hasError("Location")}
                disabled={!edit}
                helperText={
                  hasError("location") ? t("Please enter location") : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.75}>
              <Typography variant="SmallBody">{t("Bank Code")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("Bank Code")}
                name="bankCode"
                onChange={inputChange}
                value={mybankDetails.bankCode}
                error={hasError("bankCode")}
                disabled={!edit}
                helperText={
                  hasError("bankCode") ? t("Please enter bank code") : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.75}>
              <Typography variant="SmallBody">{t("Account Number")}</Typography>
              <TextField
                variant="outlined"
                placeholder="132 232 222 333"
                name="accountNumber"
                onChange={inputChange}
                value={mybankDetails.accountNumber}
                type="number"
                disabled={!edit}
                error={hasError("accountNumber")}
                helperText={
                  hasError("accountNumber")
                    ? t("Please enter account number")
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.75}>
              <Typography variant="SmallBody">
                {t("Guichet Number (if applicable)")}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={t("Guichet Number")}
                name="confirmAccountNumber"
                onChange={inputChange}
                type="number"
                value={mybankDetails.confirmAccountNumber}
                error={hasError("confirmAccountNumber")}
                disabled={!edit}
                helperText={
                  hasError("confirmAccountNumber")
                    ? t("Please enter confirm account number")
                    : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sm={12}
          md={5.9}
          lg={5.8}
          flexDirection="column"
          alignContent="flex-end"
        >
          <Typography variant="SmallBody">{t("Attachment")}</Typography>
          <DragAndDrop
            edit={edit}
            onChangeFile={(e) => setFileInformation(e.target.files[0])}
            error={edit && hasError("file")}
            defaultFileName={mybankDetails.attachmentFileName}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BankAccount;
