import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        maxHeight: "500px",
        overflowY: "auto",
        ...sx,
      }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tabs,
  tabPanels,
  sx = {},
  customTabPanelSx = {},
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mx: "auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            ".MuiTabs-flexContainer": {
              justifyContent: "center",
            },
            ...sx,
          }}
        >
          {tabs.map((tab, i) => (
            <Tab label={t(tab)} {...a11yProps(i)} key={i} />
          ))}
        </Tabs>
      </Box>
      {tabPanels.map((tabPanel, i) => (
        <CustomTabPanel
          value={value}
          index={i}
          key={i}
          sx={{
            ...customTabPanelSx,
            maxHeight: "100%",
          }}
        >
          {tabPanel}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
