import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import jwtInterceoptor from "../../services/interceptors";
import Contractstable from "./Contractstable";
import { useSnackbar } from "../Global/WithSnackbar";
import DepartmentsTable from "./DepartmentsTable";
import InfoCards from "./InfoCards";
import { useTranslation } from "react-i18next";

function EmployeeInfo() {
  const [genderData, setGenderData] = useState([]);
  const [configData, setConfigData] = useState([]);
  const { showMessage } = useSnackbar();

  const getGender = () => {
    jwtInterceoptor
      .get("api/GenderMaster/GetAllGenderMasters")
      .then((res) => {
        setGenderData(
          res.data.map((item) => ({ id: item.genderId, value: item.gender }))
        );
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });
  };

  const GetAllConfigurationValue = () => {
    jwtInterceoptor
      .get("api/ConfigurationValues/GetAllConfigurationValue")
      .then((res) => {
        setConfigData(
          res.data.map((item, i) => ({
            id: i * 2,
            value: item.value,
            title: item.key,
          }))
        );
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });
  };

  useEffect(() => {
    getGender();
    GetAllConfigurationValue();
  }, []);

  const deletGender = (id) =>
    jwtInterceoptor
      .delete(`api/GenderMaster/DeleteGenderMaster?GenderId=${id}`)
      .then((res) => {
        getGender();
        showMessage("Deleted Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const updateGender = (data) =>
    jwtInterceoptor
      .post("api/GenderMaster/UpdatedGenderMaster", {
        genderId: data.id,
        gender: data.value,
      })
      .then((res) => {
        getGender();
        showMessage("updated", "success");
        return res.data;
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const addGender = (data) =>
    jwtInterceoptor
      .post("api/GenderMaster/CreateGenderMaster", {
        gender: data.value,
      })
      .then((res) => {
        getGender();
        showMessage("added", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const onGenderSave = async ({ updatedFields, newFields, removedFields }) => {
    await newFields.forEach((item) => {
      addGender(item);
    });
    await updatedFields.forEach((item) => {
      updateGender(item);
    });
    await removedFields.forEach((item) => {
      deletGender(item.id);
    });

    getGender();
  };

  const updateConfig = (data) =>
    jwtInterceoptor
      .post("api/ConfigurationValues/Update", {
        key: data.title,
        value: data.value,
      })
      .then((res) => {
        GetAllConfigurationValue();
        showMessage("Updated Configuration data", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const addConfig = (data) =>
    jwtInterceoptor
      .post("api/ConfigurationValues/Create", {
        key: data.title,
        value: data.value,
      })
      .then((res) => {
        GetAllConfigurationValue();
        showMessage("Added Configuration data", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const deleteConfig = (data) =>
    jwtInterceoptor
      .delete(`api/ConfigurationValues/Delete?key=${data.title}`)
      .then((res) => {
        GetAllConfigurationValue();
        showMessage("Deleted Configuration data", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const onConfigSave = async ({ updatedFields, newFields, removedFields }) => {
    await newFields.forEach((item) => {
      addConfig(item);
    });
    await updatedFields.forEach((item) => {
      updateConfig(item);
    });
    await removedFields.forEach((item) => {
      deleteConfig(item);
    });

    GetAllConfigurationValue();
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        columnGap={1}
        rowGap={4}
        p={3}
        justifyContent="space-between"
      >
        <Grid
          item
          md={5.8}
          xs={12}
          className="section-border"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.common.border}`,
          })}
        >
          <h3>{t("General Settings")}</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <InfoCards
                values={genderData}
                onSave={onGenderSave}
                title="Gender"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <InfoCards
                twoTier
                title2={"Key"}
                values={configData}
                onSave={onConfigSave}
                title="Value"
                saveOnTop
                mainTitle="Configuration Values"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.8}
          className="section-border"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.common.border}`,
          })}
        >
          <Contractstable />
        </Grid>
        <Grid
          item
          xs={12}
          className="section-border"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.common.border}`,
          })}
        >
          <DepartmentsTable />
        </Grid>
      </Grid>
    </>
  );
}

export default EmployeeInfo;
