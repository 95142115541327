import React, { useEffect, useState } from "react";
import EnhancedTable from "../Global/Table";
import { Box, Button, alpha } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import AddNewContract from "./AddNewContract";
import jwtInterceoptor from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";
import DeleteModal from "../Global/DeleteModal";

function CellAction({ onEdit, id, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
        onClick={() => onEdit(id)}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={() => onDelete(id)}
      >
        <DeleteIcon />
      </Button>
      {/* <Button>
        <MoreVertIcon />
      </Button> */}
    </Box>
  );
}

function createData(contract, onEdit, onDelete) {
  return {
    contractType: contract.contractType,
    Action: <CellAction onEdit={onEdit} id={contract.id} onDelete={onDelete} />,
  };
}

function Contractstable() {
  const [newContract, setNewContract] = useState({
    open: false,
    id: null,
  });
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });
  const { showMessage } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    jwtInterceoptor
      .get("api/ContractTypeMasters/GetAllContractType")
      .then((res) => {
        setContracts(
          res.data.map((contract) => ({
            id: contract.contractTypeId,
            contractType: contract.contractType,
          }))
        );
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => setLoading(false));
  }, []);

  const handleClose = () => {
    setNewContract({
      open: false,
      id: null,
    });
  };

  const updateContract = (contractName) => {
    setLoading(true);
    jwtInterceoptor
      .post("api/ContractTypeMasters/UpdatedContractType", {
        contractTypeId: newContract.id,
        contractType: contractName,
      })
      .then((res) => {
        showMessage("Contract type Updated successfully", "success");
        setContracts((pre) =>
          pre.map((con) =>
            con.id === newContract.id
              ? { ...con, contractType: contractName }
              : con
          )
        );
        handleClose();
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => setLoading(false));
  };

  const addNewContract = (contractName) => {
    setLoading(true);
    jwtInterceoptor
      .post("api/ContractTypeMasters/CreateContractTypeDetail", {
        contractType: contractName,
      })
      .then((res) => {
        showMessage("Contract type updated successfully", "success");
        setContracts((pre) => [
          ...pre,
          {
            id: res.data.contractTypeId,
            contractType: contractName,
          },
        ]);
        handleClose();
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => setLoading(false));
  };

  const deleteContract = (id) => {
    setLoading(true);
    jwtInterceoptor
      .delete(`api/ContractTypeMasters/DeleteContractType?ContractTypeId=${id}`)
      .then((res) => {
        showMessage("Contract type deleted successfully", "success");
        setContracts((pre) => pre.filter((con) => con.id !== id));
        setDeleteModal({
          open: false,
          id: null,
        });
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => setLoading(false));
  };

  const handleSave = (contractName) => {
    if (newContract.id) {
      updateContract(contractName);
      return;
    }

    addNewContract(contractName);
  };

  const onEdit = (id) => {
    setNewContract({
      open: true,
      id,
    });
  };

  const onDelete = (id) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };

  return (
    <>
      <EnhancedTable
        head={[
          {
            id: "contactType",
            label: "Contract Type",
          },
          {
            id: "action",
            label: "Actions",
          },
        ]}
        rows={contracts.map((contract) =>
          createData(contract, onEdit, onDelete)
        )}
        isAddable={true}
        sx={{
          minWidth: "100%",
        }}
        loading={loading}
        onAddClick={() => setNewContract({ open: true, id: null })}
      />

      <AddNewContract
        open={newContract.open}
        handleClose={handleClose}
        handleSave={handleSave}
        value={
          newContract.id
            ? contracts.find((item) => item.id === newContract.id).contractType
            : ""
        }
      />

      <DeleteModal
        message={"Are you sure you want to delete this contract type?"}
        onCancel={() =>
          setDeleteModal({
            open: false,
            id: null,
          })
        }
        onConfirm={() => deleteContract(deleteModal.id)}
        open={deleteModal.open}
        title={"Delete Contract Type"}
      />
    </>
  );
}

export default Contractstable;
