export const headCells = [
  {
    id: "employeeId",
    label: "Employee ID",
  },
  {
    id: "employeeFullName",
    label: "Employee Name",
  },
  /*{
    id: "title",
    label: "Title",
  },*/
  {
    id: "month",
    label: "Month",
  },
  {
    id: "year",
    label: "Year",
  },
  // {
  //   id: "modified",
  //   label: "Modified",
  // },
  {
    id: "Action",
    label: "Action",
  },
];
