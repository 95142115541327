import React, { useEffect, useState } from "react";
import BaseModal from "../Global/Modal";
import { TextField, Typography } from "@mui/material";
import { use } from "i18next";

function AddNewContract({ open, handleClose, handleSave, value = "" }) {
  const [contractName, setContractName] = useState(value || "");
  const [error, setError] = useState(false);

  const validateAndSave = () => {
    if (!contractName && contractName.length < 1) {
      setError(true);
      return;
    }

    handleSave(contractName);
    setContractName("");
  };

  useEffect(() => {
    if (value) {
      setContractName(value);
    }

    return () => {
      setContractName("");
    };
  }, [value]);

  return (
    <BaseModal
      title="Add new contract type"
      handleClose={() => {
        setContractName("");
        handleClose();
      }}
      onSave={validateAndSave}
      open={open}
    >
      <Typography variant="SmallBody">Contract</Typography>
      <TextField
        variant="outlined"
        name="firstName"
        placeholder="Enter contract type"
        value={contractName}
        onChange={(e) => setContractName(e.target.value)}
        error={error}
        helperText={error && "Please enter a valid contract type"}
      />
    </BaseModal>
  );
}

export default AddNewContract;
