import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { Box, Stack, Typography } from "@mui/material";
import GetImage from "./GetImage";

export default function MainSlider({ slides }) {
  return (
    <Box
      sx={{
        height: "100%",
        ".swiper": {
          width: "100%",
          height: "100%",
          background: "#fff",
          ".swiper-wrapper": {
            alignItems: "center",
          },
          border: (theme) => `1px solid ${theme.palette.common.border}`,
        },

        ".swiper-slide": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          img: {
            width: "100%",
            height: "180%",
            objectFit: "contain",
          },
          color: "#092C4C",

          svg: {
            width: "150px",
            height: "150px",
            fill: "#092C4C",
          },
        },
      }}
    >
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {slides.length > 0 &&
          slides?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <GetImage presentation={item} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </Box>
  );
}
