import React from "react";
import { Box } from "@mui/material";
import { SUB_MENU } from "../../pages/AdminSettings";
import EmployeeInfo from "./EmployeeInfo";
import LeaveManagement from "./LeaveManagement";
import SkillsManagement from "./SkillsManagement";
import AnnouncementsList from "../dashboard/AnnouncementsList";
import Assets from "../AdminSettings/Assets";

function SubMenu({ activeMenu }) {
  switch (activeMenu) {
    case SUB_MENU.employeeInfo:
      return <EmployeeInfo />;
    case SUB_MENU.leaveManagent:
      return <LeaveManagement />;
    case SUB_MENU.skillManagement:
      return <SkillsManagement />;
    case SUB_MENU.announcement:
      return <AnnouncementsList />;
      case SUB_MENU.asset:
        return <Assets/>;
    default:
      return <EmployeeInfo />;
  }
}

function AdminSettingsSubMenu({ activeMenu }) {
  return (
    <Box
      className="section-border"
      mt="20px"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.border}`,
      })}
    >
      <SubMenu activeMenu={activeMenu} />
    </Box>
  );
}

export default AdminSettingsSubMenu;
