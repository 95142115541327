import { Box } from "@mui/material";
import React from "react";
import { PenIcon } from "../../assets/images";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

function EditAndSave({
  edit,
  setEdit,
  onSave = () => {},
  onUpdate = () => {},
  onCancel = () => {},
  showConfirm = true,
  isManagerOrAdmin
}) {
   
 
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        ml: "auto",
        flexWrap: "wrap",

        ...(!edit && {
          backgroundColor: "#4959ed14",
          "& svg": {
            path: {
              fill: "#4959ED",
            },
          },
        }),

        ...(edit && {
          width: "100%",
          justifyContent: "flex-end",
        }),
      }}
    >
      {showConfirm && edit ? (
        <>
          <CancelRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setEdit(false);
              onCancel();
            }}
            style={{ width: "40px", height: "40px" }}
            color="error"
          />
          <CheckCircleRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              onUpdate();
            }}
            style={{ width: "40px", height: "40px" }}
            color="success"
          />
        </>
      ) : (
        <PenIcon
          sx={{ cursor: "pointer",display: isManagerOrAdmin, }}
          onClick={() => {
            setEdit(true);
            onSave();
          }}
        />
      )}
    </Box>
  );
}

export default EditAndSave;
