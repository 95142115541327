import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import MainSlider from "../components/Presentation/MainSlider";
import PresentationInfo from "../components/Presentation/PresentationInfo";
import CardsSlide from "../components/Presentation/CardsSlide";
import EditPresentations from "../components/Presentation/EditPresentations";
import DeleteModal from "../components/Global/DeleteModal";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import jwtInterceoptor from "../services/interceptors";
import { useSnackbar } from "../components/Global/WithSnackbar";
import { ProgressLoader } from "../components/Global/GlobalLoader";

const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL;

function Presentation() {
  const [edit, setEdit] = useState({
    edit: false,
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const [presentations, setPresentations] = useState([]);
  const [selected, setSelected] = useState(6);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const roleskey = sessionStorage.getItem("roles");
  let isManagerOrAdmin =
    roleskey.includes("Manager") || roleskey.includes("Admin")
      ? "flex"
      : "none";

  const getPresentation = () => {
    return presentations.find((item) => item.id === selected);
  };

  const selectPresentation = (id) => {
    setSelected(id);
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const onEditClick = () => {
    setEdit({ edit: true, id: selected });
  };

  const onAddClick = () => {
    setEdit({ edit: true, id: "" });
  };

  const getPresentations = async () => {
    setLoading(true);
    jwtInterceoptor
      .get("api/PresentationDetail/GetAllPresentationDetail")
      .then((res) => {
        let presentationList = [];
        for (var row of res.data) {
          console.log(row);
          let item = {
            id: row.companyDetailId,
            img: getFullUrl(row),
            title: row.title,
            posted: new Date(row.postedOn).toLocaleDateString("en-GB"),
            format: "Pdf",
            user: {
              name: row.userName,
              img:
                process.env.REACT_APP_API_PROFILE_SERVICE_URL +
                "/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
                row.employeeId,
            },
            description: row.description,
            presentations: [],
            isVisibleToUser: row.isVisibleToUser ?? true,
          };
          if (row.fileName != null) {
            for (var filename of row.fileName.split(",")) {
              let presentationItem = {
                url: getUrl(row.companyDetailId, filename),
                name: filename ?? "image1",
                type: filename.split(".")[1],
              };
              item.presentations.push(presentationItem);
            }
            presentationList.push(item);
          }
        }
        setSelected(presentationList[0].id);
        setPresentations(presentationList);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const getFullUrl = (item) => {
    return item.fileName
      ? API_URL +
          "api/PresentationDetail/OpenPresentationDetailFile/" +
          item.companyDetailId +
          "/" +
          item.fileName.split(",")[0]
      : "";
  };
  const getUrl = (id, fileName) => {
    return (
      API_URL +
      "api/PresentationDetail/OpenPresentationDetailFile/" +
      id +
      "/" +
      fileName
    );
  };

  const createNewPresentationConfig = async (data) =>
    jwtInterceoptor
      .post("api/PresentationDetail/CreatePresentationDetail", data)
      .then((res) => {
        showMessage("Presentation created successfully", "success");
        getPresentations();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const updatePresentationConfig = async (data) =>
    jwtInterceoptor
      .post("api/PresentationDetail/UpdatePresentationDetail", data)
      .then((res) => {
        showMessage("Presentation Updated successfully", "success");
        getPresentations();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const deletePresentationFile = async (id, fileName) =>
    jwtInterceoptor
      .delete(
        `api/PresentationDetail/DeletePresentationDetailFile?CompanyDetailId=${id}&file=${fileName}`
      )
      .then((res) => {
        showMessage("Presentation Updated successfully", "success");
        getPresentations();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const deleteDeletedPresentationFiles = async (deletedFiles, id) => {
    await Promise.all(
      deletedFiles.map((file) => deletePresentationFile(id, file.name))
    );

    getPresentations();
  };

  const deletePresentationConfig = async (id) =>
    jwtInterceoptor
      .delete(
        `api/PresentationDetail/DeletePresentationDetail?CompanyDetailId=${id}`
      )
      .then((res) => {
        showMessage("Presentation Updated successfully", "success");
        getPresentations();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  React.useEffect(() => {
    getPresentations();
  }, []);

  const UploadDocuments_Click = (data) => {
    //console.log("Save data"+data.Title);
    if (!edit.id) createNewPresentationConfig(data);
    else updatePresentationConfig(data);
  };

  return (
    <Box
      sx={{
        borderRadius: "20px",
        p: "15px",
        mt: "15px",
        pr: "40px",

        width: "98%",
      }}
    >
      <ProgressLoader loading={loading} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t("Company presentation")}</Typography>
        <Button
          variant="outlined"
          sx={{
            padding: "10px",
            width: "fit-content",
            display: isManagerOrAdmin,
          }}
          onClick={onAddClick}
        >
          <Add />
          {t("Add New")}
        </Button>
      </Stack>
      <Grid
        sx={{
          border: (theme) => `1px solid ${theme.palette.common.border}`,
          borderRadius: "20px",
          p: "15px",
          m: "15px 0",
          height: "55vh",

          [theme.breakpoints.down("md")]: {
            height: "auto",
          },
        }}
        container
        spacing={3}
      >
        <Grid item xs={12} md={7}>
          <MainSlider
            slides={presentations.length > 0 && getPresentation().presentations}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <PresentationInfo
            onEditClick={onEditClick}
            selected={presentations.length > 0 && getPresentation()}
            handleDelete={handleDelete}
            isManagerOrAdmin={isManagerOrAdmin}
          />
        </Grid>
      </Grid>
      <CardsSlide
        presentations={presentations.length > 0 && presentations}
        selectPresentation={selectPresentation}
      />
      <EditPresentations
        id={edit.id}
        open={edit.edit}
        onClose={() => {
          setEdit({ edit: false, id: "" });
        }}
        onSave={({ formDataFiles, removedFiles }) => {
          deleteDeletedPresentationFiles(removedFiles, edit.id);
          UploadDocuments_Click(formDataFiles);
        }}
        data={
          presentations.length > 0 &&
          presentations.find((item) => item.id === edit.id)
        }
      />
      <DeleteModal
        message={"Are you sure you want to delete this presentation?"}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          deletePresentationConfig(selected);
          setDeleteModal(false);
        }}
        title={"Delete presentation"}
        open={deleteModal}
      />
    </Box>
  );
}

export default Presentation;
