import React, { useState, useEffect, useRef } from "react";
import { CircularChip } from "../../Global/Chips";
import EnhancedTable from "../../Global/Table";
import LeaveRequest from "./LeaveRequest";
import profileService from "../../../services/myprofile";
import LeaveHistory from "./LeaveHistory";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../Global/WithSnackbar";
import { jwtLeave } from "../../../services/interceptors";

const headCells = [
  {
    id: "leave",
    label: "Leave Type",
  },
  {
    id: "entitled_leaves",
    label: "Entitled leaves",
  },
  {
    id: "applied_leaves",
    label: "Leaves applied",
  },
  {
    id: "approved_leaves",
    label: "Leaves approved",
  },

  {
    id: "leaves_pending_approval",
    label: "Leaves pending for approval",
  },
  {
    id: "balance",
    label: "Balance",
  },
];

function createData(
  leave,
  entitled_leaves,
  applied_leaves,
  approved_leaves,
  leaves_pending_approval,
  balance
) {
  return {
    leave,
    entitled_leaves,
    applied_leaves,
    approved_leaves,
    leaves_pending_approval,
    balance,
  };
}

function EmployeeLeaveTable({}) {
  const [open, setopen] = useState(false);
  const tblRows = [];
  const initialized = useRef(false);
  const [leavesData, setleavesDataState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaveHistoryModal, setLeaveHistoryModal] = useState(false);
  const [leaveId, setIdState] = useState(null);
  const { showMessage } = useSnackbar();

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      GetLeavesListData();
    }
  }, []);

  function onHistory(eId) {
    setLeaveHistoryModal((pre) => !pre);
    setIdState(eId);
  }
  const GetLeavesListData = async () => {
    setLoading(true);
    let url = "api/EmployeeLeave/GetLeaveDashboard?EmployeeDetailId=" + empId;
    jwtLeave
      .get(url)
      .then((response) => {
        for (var x of response.data.leaveDashboardDto) {
          let eId = x.leaveTypeId;
          tblRows.push(
            createData(
              <Link
                color="#18A0FB !important"
                onClick={() => onHistory(eId)}
                style={{
                  textDecoration: "underline",
                  color: "#18A0FB !important",
                }}
              >
                {x.leaveType}
              </Link>,
              <CircularChip value={x.daysEntitled} color="#18A0FB" />,
              <CircularChip value={x.appliedLeaves} color="#964CF5" />,
              <CircularChip value={x.approvedLeaves} color="#03B525" />,
              <CircularChip value={x.pendingLeaves} color="#E2B93B" />,
              <CircularChip value={x.balance} color="#092C4C" />
            )
          );
        }
        setleavesDataState(tblRows);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={leavesData}
        isAddable={true}
        onAddClick={() => setopen((pre) => !pre)}
        loading={loading}
        title={"Employee Leaves"}
      />
      <LeaveRequest
        open={open}
        onClose={() => setopen(false)}
        onSave={() => {
          GetLeavesListData();
        }}
      />
      <LeaveHistory
        open={leaveHistoryModal}
        handleClose={() => setLeaveHistoryModal(false)}
        leaveData={leaveId}
      />
    </>
  );
}

export default EmployeeLeaveTable;
