import React, { useEffect, useState } from "react";
import EnhancedTable from "../Global/Table";
import { Box, Button, Typography, alpha } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Circle } from "@mui/icons-material";
import AddNewAsset from "./AddNewAsset";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DeleteModal from "../Global/DeleteModal";
import jwtInterceoptor from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";

const headCells = [
  {
    id: "equipment",
    label: "Equipment",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "registration",
    label: "Registration",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
  },
];


function createData(equipment, brand, model, registration, expiryDate, id, onEdit, onDelete) {
  return {
    equipment,
    brand,
    model,
    registration,
    expiryDate,
    Action: (
      <CellAction onEdit={() => onEdit(id)} onDelete={() => onDelete(id)} />
    ),
  };
}

function Status({ status }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <Circle color={status === "Active" ? "success" : "error"} />
      <Typography variant="smallBodyBold">{status}</Typography>
    </Box>
  );
}

function CellAction({ onEdit, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
        onClick={onEdit}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={onDelete}
      >
        <DeleteIcon />
      </Button>
      {/*<Button>
        <MoreVertIcon />
      </Button>*/}
    </Box>
  );
}



function Assets() {
  //const [open, setOpen] = useState(false);
  const [open, setOpen] = useState({
    open: false,
    id: null,
  });

  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const [assetConfig, setAssetConfig] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });
  const getAssetConfig = async () => {
    setLoading(true);
    jwtInterceoptor
      .get("api/HrAsset/GetAllAssetConfigurationList")
      .then((res) => {
        setAssetConfig(res.data);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });
    setLoading(false);
  };

  const updateAssetConfig = async (data) =>
  jwtInterceoptor
      .post("api//HrAsset/UpdateAssetConfiguration", data)
      .then((res) => {
        showMessage("Asset configuration updated successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const createNewAssetConfig = async (data) =>
      jwtInterceoptor
      .post("api/HrAsset/CreateAssetConfiguration", data)
      .then((res) => {
        showMessage("Asset configuration created successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const deleteLeaveConfig = async (id) =>
    jwtInterceoptor
      .delete(
        `api/HrAsset/DeleteAssetConfiguration?AssetConfigurationId=${id}`
      )
      .then((res) => {
        showMessage("Asset configuration deleted successfully", "success");
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });

  const onEdit = (id) => {
    setOpen({
      open: true,
      id: id,
    });
  };

  const onDelete = (id) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };
  const onDeleteConfirm = async () => {
    await deleteLeaveConfig(deleteModal.id);
    getAssetConfig();
    setDeleteModal({
      open: false,
      id: null,
    });
  };

  const onSave = async (data) => {
    if (data.assetConfigurationId) {
      await updateAssetConfig(data);
    } else {
      await createNewAssetConfig(data);
    }

    getAssetConfig();

    setOpen({
      open: false,
      id: null,
    });
  };

  React.useEffect(() => {
    getAssetConfig();
  }, []);

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={assetConfig.map((item) =>
          createData(
            item.equipment,
            item.brand,
            item.model,
            (item.registration!=null && item.registration!="" && item.registration!="string") ?dayjs(item?.registration).format("DD/MM/YYYY"):"",
            dayjs(item?.expiryDate).format("DD/MM/YYYY"),
            item.assetConfigurationId,
            onEdit,
            onDelete,
          )
        )}
        isAddable={true}
        onAddClick={() =>
          setOpen({
            open: true,
            id: null,
          })
        }
        title="Asset Configuration"
        loading={loading}
      />
      <AddNewAsset
        open={open.open}
        handleClose={() => {
          setOpen({
            open: false,
            id: null,
          });
        }}
        handleSave={onSave}
        asset={assetConfig.find((item) => item.assetConfigurationId === open.id)}
      />

      <DeleteModal
        open={deleteModal.open}
        message={"Are you sure want to delete this Asset?"}
        title={"Delete Asset"}
        onCancel={() => {
          setDeleteModal({
            open: false,
            id: null,
          });
        }}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

export default Assets;
