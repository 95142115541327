import {
  FormControlLabel,
  FormGroup,
  Radio,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { themeContext } from "../../../../theme";

function UserOption({ name, icon, options, onClick = () => {} }) {
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation();
  const { myTheme, changeTheme } = useContext(themeContext);

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="start"
        padding="7px 10px"
        sx={(theme) => ({
          borderRadius: "10px",
          cursor: "pointer",
          "&:hover": {
            padding: "13px 10px",
            backgroundColor: alpha(theme.palette.info.main, 0.1),
          },
        })}
        onClick={onClick}
      >
        <img src={icon} alt="" />
        <Typography
          variant="body"
          onClick={() => setShowOptions((pre) => !pre)}
        >
          {t(name)}
        </Typography>
      </Stack>
      {showOptions && (
        <FormGroup
          sx={{
            ml: 2,
          }}
        >
          {options
            .filter((op) => op !== "dark")
            .map((option, id) => (
              <FormControlLabel
                control={
                  <Radio size="small" checked={option === myTheme.name} />
                }
                label={String(option[0]).toUpperCase() + option.slice(1)}
                onClick={() => changeTheme(option)}
              />
            ))}
        </FormGroup>
      )}
    </div>
  );
}

export default UserOption;
