import React from "react";
import BaseModal from "./Modal";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function DeleteModal({ open, onConfirm, onCancel, title, message }) {
  const { t } = useTranslation();
  return (
    <BaseModal
      open={open}
      handleClose={onCancel}
      title={title}
      yesOrNo={true}
      onSave={onConfirm}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="SmallBody">
            {t(message || "Do you want to delete the selected item ?")}
          </Typography>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

export default DeleteModal;
