import React, { useEffect, useState, useRef } from "react";
import EnhancedTable from "../../Global/Table";
import { Checkbox, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import BaseModal from "../../Global/Modal";
import hrService from "../../../services/hrdashboardservice";
import { useNavigate } from "react-router-dom";
import { Refresh, TroubleshootSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import jwtInterceptor from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";

const states = [
  {
    label: "Granted",
    color: "success",
  },
  {
    label: "Returned",
    color: "warning",
  },
];

const headCells = [
  {
    id: "equipment",
    label: "Equipment",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "registrationNumber",
    label: "Registration Number",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

function createData(
  equipment,
  brand,
  model,
  registrationNumber,
  status,
  comments
) {
  return {
    equipment,
    brand,
    model,
    registrationNumber,
    status,
    comments,
  };
}

function Status({ item, handleChange }) {
  const [isAssetAssigned, setIsAssetAssigned] = useState(item.isAssetAssigned);

  const handleCheckboxChange = (check) => {
    setIsAssetAssigned(check);
    handleChange(check, item);
  };

  return (
    <Stack direction="row" gap="5px" alignItems="center">
      <Checkbox
        checked={isAssetAssigned}
        color={"success"}
        onChange={(e) => handleCheckboxChange(true)}
      />
      <Checkbox
        checked={!isAssetAssigned}
        color={"warning"}
        onChange={(e) => handleCheckboxChange(false)}
      />
    </Stack>
  );
}

function NewAssetModal({
  open,
  handleClose,
  title,
  selectedId,
  onSave,
  data,
  setData,
}) {
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const navigate = useNavigate();
  const [assetData, setassetData] = useState([]);
  //const [openModel, setopenModel] = useState(open);
  const [assignAssets, setassignAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();

  const initialized = useRef(false);

 // console.log(open+""+openModel);

  const checkboxChanged = (assettatus, assetItem) => {
    setassignAssets((prevData) => {
      // Use the map method to find the object with the specified id and update it
      return prevData.map((item) => {
        if (assetItem.assetConfigurationId === item.assetConfigurationId) {
          return { ...item, isAssetAssigned: assettatus };
        }
        return item;
      });
    });
  };

  const GetAssetsListData = async () => {
    setLoading(true);
    let url =
      "api/HrAsset/GetAssetListByEmployeeId?EmployeeDetailId=" + selectedId;

   /* let response = await hrService
      .GetAssetListDataRequest(url, bearerToken)
      .finally(() => setLoading(false));
    let tblRows = [];
    if (response.status !== 200) navigate("/login", { replace: true });
    else {
      
    }*/

    jwtInterceptor
    .get(url)
    .then((response) => {
      let tblRows = [];
      for (var x of response.data) {
        let eId = x.assetConfigurationId;
        let item = x;
        console.log("item", item);
        tblRows.push(
          createData(
            x.equipment,
            x.brand,
            x.model,
            x.registration,
            "", //new Date(x.dateOfJoining).toLocaleDateString("en-GB"),
            <Status item={item} handleChange={checkboxChanged} />,
            x.comment
          )
        );
      }

      setassetData(tblRows);
      setassignAssets(response.data);
      open = false;
    })
    .finally(() => setLoading(false));
  };
  //Update

  /*if (open) {
    if (!initialized.current) {
      //initialized.current = true;
      // setassetData([]);
     // GetAssetsListData();
    }
  }*/

  useEffect(() => {
    if (open && !initialized.current) {
      setassetData([]);
      initialized.current = true;
     // setopenModel(false);
      GetAssetsListData();
    }
  });

  function save() {
    assignAssetsToEmployee();
  }

  function close() {
    initialized.current = false;
    handleClose();
  }

  const assignAssetsToEmployee = async () => {
    let assignedAssets = [];
    assignAssets.map((asset) => {
      let item = {
        assetConfigurationId: asset.assetConfigurationId,
        registration: asset.registration,
        isAssetAssigned: asset.isAssetAssigned,
        comment: asset.comment,
      };
      assignedAssets.push(item);
    });

    let url = "api/HrAsset/AddUpdateAssignAsset?EmployeeDetailId=" + selectedId;
    /*let response = await hrService.updateAssetsRequest(
      url,
      assignedAssets,
      bearerToken
    );*/

    jwtInterceptor
    .post(url,assignedAssets)
    .then((response) => {
      showMessage(response.data, "success");
    });
    //GetAssetsListData();
    //console.log(assignedAssets);
    initialized.current = false;
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <BaseModal
      open={open}
      handleClose={close}
      title={title}
      onSave={save}
      sx={{
        maxWidth: "fit-content",
      }}
    >
      <EnhancedTable
        head={headCells}
        rows={assetData}
        hidePagination
        loading={loading}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {states.map((state, i) => (
          <Stack direction="row" gap="5px" alignItems="center" key={i}>
            <CircleIcon fontSize="12px" color={state.color} />
            <Typography variant="Small">{t(state.label)}</Typography>
          </Stack>
        ))}
      </Stack>
    </BaseModal>
  );
}

export default NewAssetModal;
