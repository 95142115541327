import React, { useState } from "react";
import EnhancedTable from "../Global/Table";
import { Box, Button, Typography, alpha } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Circle } from "@mui/icons-material";
import AssetsModal from "./Assets/AssetsModal";
import DeleteModal from "../Global/DeleteModal";

const headCells = [
  {
    id: "employeeId",
    label: "Employee ID",
  },
  {
    id: "fullName",
    label: "Full Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "lineManager",
    label: "Line Manager",
  },
  {
    id: "startingDate",
    label: "Starting Date",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(
  employeeId,
  fullName,
  department,
  lineManager,
  startingDate,
  status,
  Action
) {
  return {
    employeeId,
    fullName,
    department,
    lineManager,
    startingDate,
    status,
    Action,
  };
}

function Status({ status }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <Circle color={status === "Active" ? "success" : "error"} />
      <Typography variant="smallBodyBold">{status}</Typography>
    </Box>
  );
}

function CellAction({ onDeleteClick }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={onDeleteClick}
      >
        <DeleteIcon />
      </Button>
      <Button>
        <MoreVertIcon />
      </Button>
    </Box>
  );
}

function EmployeeSkillsTable() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  /*const tblRows = [];
  const [employeeData, setemployeeDataState] = useState([]);
  const [newEmployeeData, setnewEmployeeDataState] = useState({});

  const bearerToken =  sessionStorage.getItem("token_key");
  const empId =  sessionStorage.getItem("empId_key");

  useEffect(() => {
    //GetEmployeeListData();
  },[]);

  const GetEmployeeListData = async () => {
    let url = "api/HrEmployeeDetail/GetEmployeeDetailsList";
    let response = await hrService.GetEmployeeListDataRequest(url,bearerToken);
    for (var x of response) {   

      tblRows.push(
        createData(
         x.employeeId,
         x.fullName,
         x.department,
         x.lineManager,
         x.dateOfJoining,
         <Status status={x.status} />,
         <CellAction
        onEdit={() => {}}
        onDelete={() => setDeleteModal((pre) => !pre)}
      />
        )
      );
    }
    setemployeeDataState(tblRows);
  
  };*/

  const rows = [
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance",
      "Clay Thomas",
      "09 Sep, 2021",
      <Status status="Active" />,
      <CellAction onDeleteClick={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance",
      "Clay Thomas",
      "09 Sep, 2021",
      <Status status="Deactive" />,
      <CellAction onDeleteClick={() => setDeleteOpen((pre) => !pre)} />
    ),
  ];

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={rows}
        isAddable={true}
        onAddClick={() => setOpen((pre) => !pre)}
        title="Employees information"
      />
      <AssetsModal
        open={open}
        handleClose={() => setOpen((pre) => !pre)}
        onSave={() => {}}
        title="Add new asset"
      />
      <DeleteModal
        message="Are you sure you want to delete this Asset?"
        open={deleteOpen}
        onCancel={() => setDeleteOpen((pre) => !pre)}
        onDelete={() => {}}
        title="Delete Asset"
      />
    </>
  );
}

export default EmployeeSkillsTable;
