import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import CircleIcon from "@mui/icons-material/Circle";

import { useSnackbar } from "../Global/WithSnackbar";
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { frFR } from "@mui/x-date-pickers/locales";
import { useTranslation } from "react-i18next";

let daysToHighlight = [];
let allLeaves = [];

const BankHoliday = ({ name }) => (
  <Tooltip title={name} placement="top">
    <CircleIcon color="error" fontSize="10px" />
  </Tooltip>
);
const LeaveTaken = ({ name }) => (
  <Tooltip title={name} placement="top">
    <CircleIcon color="success" fontSize="10px" />
  </Tooltip>
);

const customStyles = {
  width: "100%",
  height: "fit-content",
  ".MuiPickersSlideTransition-root": {
    overflow: "hidden",
  },
  ".MuiDayCalendar-monthContainer": {
    // target each direct child div of the month container
    "& > div": {
      marginBottom: "16px",
    },
  },
  ".MuiDayCalendar-header": {
    justifyContent: "space-around",
    "& > span": {
      color: "#18A0FB",
      fontWeight: 600,
      fontSize: "18px",
    },
  },
  ".MuiDayCalendar-weekContainer": {
    justifyContent: "space-around",

    button: {
      height: "100% !important",
    },
  },
  ".MuiPickersCalendarHeader-labelContainer": {
    color: "#18A0FB",
    fontWeight: 600,
    fontSize: "18px",

    path: {
      fill: "#18A0FB",
    },
  },
  ".MuiButtonBase-root.Mui-selected": {
    color: "#fff",
    padding: "5px",
  },
  ".MuiPickersArrowSwitcher-root": {
    scale: "1.1",

    path: {
      fill: "#18A0FB",
    },
  },
  ".MuiPickersCalendarHeader-root": {
    marginBottom: "24px",
  },
  ".MuiPickersCalendarHeader-label": {
    textTransform: "capitalize",
  },
};

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */
function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      /* const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );*/
      daysToHighlight = [];
      for (var x of allLeaves) {
        if (x.month == date.$M && x.year == date.$y) {
          daysToHighlight.push(x);
        }
      }

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs(new Date());

const getHighlightContent = (isHoliday, isLeave, name) => {
  if (isHoliday) {
    return <BankHoliday name={name} />;
  }

  if (isLeave) {
    return <LeaveTaken name={name} />;
  }

  return undefined;
};

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const toDayHighlight = highlightedDays.find(
    (highlightedDay) => highlightedDay.day === dayjs(day).date()
  );

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={getHighlightContent(
        toDayHighlight?.isHoliday,
        toDayHighlight?.isLeave,
        toDayHighlight?.name
      )}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          fontSize: "18px",
        }}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest() {
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const {
    i18n: { language },
  } = useTranslation();

  // const lng = localStorage.getItem();

  useEffect(() => {
    if (bearerToken) {
      getMyLeavesData();
    } else {
      navigate("/login");
    }
  }, [language]);

  const getMyLeavesData = async () => {
    setIsLoading(true);
    jwtInterceptor
      .get(
        "api/PublicHoliday/GetAllLeavesAndPublicHolidays?EmployeeDetaailId=" +
          empId
      )
      .then((response) => {
        //setpresentaion(response.data);
        daysToHighlight = [];
        allLeaves = [];
        for (var x of response.data) {
          let item = {
            id: 1,
            day: new Date(x.startDate).getDate(),
            month: new Date(x.startDate).getMonth(),
            year: new Date(x.startDate).getFullYear(),
            isHoliday: x.category == "Holiday" ? true : false,
            isLeave: x.category == "Leave" ? true : false,
            name: x.name,
          };

          allLeaves.push(item);
        }
        for (var x of allLeaves) {
          if (x.month == initialValue.$M && x.year == initialValue.$y) {
            daysToHighlight.push(x);
          }
        }

        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      });
  };
  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}
    daysToHighlight = [];
    for (var x of allLeaves) {
      if (x.month == date.$M + 1 && x.year == date.$y) {
        daysToHighlight.push(x);
      }
    }

    setHighlightedDays(daysToHighlight);

    setIsLoading(true);
    // setHighlightedDays([]);
    fetchHighlightedDays(date);
    getMyLeavesData();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      <DateCalendar
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        sx={customStyles}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}
