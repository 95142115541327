import React, { useState, useRef, useEffect } from "react";
import BaseModal from "../../Global/Modal";
import { RoundedChip } from "../../Global/Chips";
import EnhancedTable from "../../Global/Table";
import profileService from "../../../services/myprofile";
import { jwtLeave } from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";

const headCells = [
  {
    id: "leave_type",
    label: "Leave Type",
  },
  {
    id: "start_date",
    label: "Start Date",
  },
  {
    id: "end_date",
    label: "End Date",
  },
  {
    id: "applied_leaves",
    label: "Applied Leaves",
  },

  {
    id: "status",
    label: "Status",
  },
  {
    id: "comments",
    label: "Comments",
  },
];

const STATUS = {
  APPROVED: "#03B525",
  PENDING: "#E2B93B",
  REJECTED: "#E74C3C",
};

function createData(
  leave_type,
  start_date,
  end_date,
  applied_leaves,
  status,
  comments
) {
  return {
    leave_type,
    start_date,
    end_date,
    applied_leaves,
    status,
    comments,
  };
}


function LeaveHistory({ handleClose, open, leaveData }) {
  const [leavesHistoryData, setleavesHistoryDataState] = useState([]);
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const GetLeavesHistoryListData = async () => {
    setLoading(true);
    let url =
      "api/EmployeeLeave/GetEmployeeLeaveListByLeaveType?EmployeeDetailId=" +
      empId +
      "&LeaveTypeId=" +
      leaveData;

      jwtLeave
      .get(url)
      .then((response) => {
        let tblRows = [];
        initialized.current = false;
        for (var x of response.data) {
          tblRows.push(
            createData(
              x.leaveType,
              new Date(x.startDate).toLocaleDateString("en-GB"),
              new Date(x.endDate).toLocaleDateString("en-GB"),
              x.totalDays,
              <RoundedChip status={x.leaveStatus} color={STATUS.REJECTED} />,
              x.managerComment
            )
          );
        }
        setleavesHistoryDataState(tblRows);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
   

  };

  

  if (open) {
    if (!initialized.current) {
      initialized.current = true;
      setleavesHistoryDataState([]);
      GetLeavesHistoryListData();
    }
  }
  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Leave History"
      showSaveButton={false}
      sx={{
        maxWidth: "fit-content",
      }}
    >
      <EnhancedTable
        head={headCells}
        rows={leavesHistoryData}
        loading={loading}
      />
    </BaseModal>
  );
}

export default LeaveHistory;
