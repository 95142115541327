import { LinearProgress } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import BaseModal from "./Modal";
import { useTranslation } from "react-i18next";

const LoaderContext = createContext();

export const useLoader = () => {
  return useContext(LoaderContext);
};

export const ProgressLoader = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      open={loading}
      handleClose={() => {}}
      title={t("Please wait...")}
      showSaveButton={false}
      isCloseIcon={false}
    >
      <LinearProgress />
    </BaseModal>
  );
};

function GlobalLoader({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {children}
      {loading && <ProgressLoader loading={loading} />}
    </LoaderContext.Provider>
  );
}

export default GlobalLoader;
