import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CircularIcon from "../Global/CircularIcon";
import { useTranslation } from "react-i18next";

import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";

function Announcements() {
  const { t } = useTranslation();
  const [announcement, setannouncement] = useState([]);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getAnnouncementData();
      } else {
        navigate("/login");
      }
    }
  }, []);
  const getAnnouncementData = async () => {
    jwtInterceptor
      .get("api/Anouncement/GetActiveAnnouncement")
      .then((response) => {
        setannouncement(response.data);
      });
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          borderRadius: "20px",
          background: theme.palette.background.backLessOps,
          p: "15px",
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularIcon icon={<AnnouncementIcon />} color="#E2B93B" />
            <Typography variant="body">{t("Announcements")}</Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            p: 2,
          }}
        >
          {announcement.length > 0 && (
            <Typography variant="extraSmallBody">
              {announcement[0].description}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Announcements;
