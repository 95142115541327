import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedTable from "../../../Global/Table";
import DocIcon from "@mui/icons-material/InsertDriveFile";
import PDFIcon from "@mui/icons-material/Description";
import FileIcon from "@mui/icons-material/AttachFile";
import profileService from "../../../../services/myprofile";
import BaseModal from "../../../Global/Modal";
import DragAndDrop from "../../../Global/DragAndDrop";
import jwtInterceptor from "../../../../services/interceptors";
import { useSnackbar } from "../../../Global/WithSnackbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL+"/api/";

const headCells = [
  {
    id: "title",
    label: "Title",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "modified_by",
    label: "Modified By",
  },
  {
    id: "modified",
    label: "Modified",
  },

  {
    id: "action",
    label: "Action",
  },
];

function createData(id, title, type, modified, modified_by, action) {
  return {
    id,
    title,
    type,
    modified,
    modified_by,
    action,
  };
}

function CellAction({ id, name, onDelete }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <Button
        sx={{
          width: "30px",
          height: "30px",
          minWidth: "30px",
          minHeight: "30px",
          borderRadius: "50%",
          backgroundColor: "#df6f7813",

          svg: {
            width: "18px",
            height: "18px",
          },
        }}
        onClick={() => {
          handleDownload(id, name);
        }}
      >
        <CloudDownloadIcon color="error" />
      </Button>

      <Button
        onClick={() => {
          onDelete(id);
        }}
        sx={{
          width: "30px",
          height: "30px",
          minWidth: "30px",
          minHeight: "30px",
          borderRadius: "50%",
          backgroundColor: "#df6f7813",

          svg: {
            width: "18px",
            height: "18px",
          },
        }}
      >
        <DeleteIcon color="error" />
      </Button>
      <MoreVertIcon />
    </Box>
  );
}

const DocumentIcon = {
  Document: <DocIcon />,
  PDF: <PDFIcon />,
  File: <FileIcon />,
};

const handleDownload = async (id, filename) => {
  let url = API_URL + "api/EmployeeDocuments/DownloadDocument/" + id;
  console.log(url);
  fetch(url, {
    method: "Get",
    headers: { "Content-Type": "application/pdf" },
  })
    .then((response) => response.blob())
    .then((response) => {
      // create "a" HTML element with href to file & click
      const href = URL.createObjectURL(response);
      //console.log(href);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

function DocumentType({ type }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",

        svg: {
          width: "13px",
          fill: "#18A0FB",
        },
      }}
    >
      {DocumentIcon[type]}
      {t(type)}
    </Box>
  );
}

function Documents({ employeeId }) {
  const [open, setOpen] = useState(false);
  const [formData, setformdatastate] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selecteddocument, setSelecteddocument] = useState(0);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();

  const [employeeDocuments, setEmployeeDocumentState] = useState([]);
  const tblRows = [];

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = employeeId || sessionStorage.getItem("empId_key");

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetDocumentListData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const setFileInformation = (files) => {
    const formDataFiles = new FormData();
    for (let i = 0; i < files.length; i++) {
      formDataFiles.append("files", files[i]);
      formDataFiles.append("name", files[i].name);
    }
    setformdatastate(formDataFiles);
  };

  const GetDocumentListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/EmployeeDocuments/GetDocumentList?EmployeeDetailId=" + empId)
      .then((response) => {
        for (var x of response.data) {
          const formattedDate = new Date(x.modifiedDate).toLocaleDateString(
            "en-US",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }
          );
          tblRows.push(
            createData(
              x.employeeDocumentId,
              x.fileName,
              <DocumentType type={x.fileType} />,
              x.modifiedBy,
              formattedDate,
              <CellAction
                id={x.employeeDocumentId}
                name={x.fileName}
                onDelete={(id) => {
                  setSelecteddocument(id);
                  setDeleteModal(true);
                }}
              />
            )
          );
        }
        setEmployeeDocumentState(tblRows);
      })
      .finally(() => setLoading(false));
  };
  const UploadDocuments_Click = () => {
    uploadDocumentsData();
    setOpen(false);
  };
  const uploadDocumentsData = async () => {
    let url =
      "api/EmployeeDocuments/UploadEmployeeDocument?EmployeeDetailId=" + empId;
   /* let response = await profileService.uploadDocumentsDataRequest(
      url,
      formData,
      bearerToken
    );*/

    jwtInterceptor
    .post(url,formData)
    .then((response) => {
      showMessage(response.data, "success");
      GetDocumentListData();
    });

   
  };
  const handleDelete = async () => {
    let url =
      "api/EmployeeDocuments/DeleteDocument?employeeDocumentId=" + selecteddocument;
    //let response = await profileService.deleteDocumentRequest(url, bearerToken);   

    jwtInterceptor
    .delete(url)
    .then((response) => {
      showMessage(response.data, "success");
      GetDocumentListData();
      setDeleteModal(false);
    });
  };



  const downloadDocument = async (documentName) => {
    let url = "api/EmployeeDocuments/DownloadDocument/" + documentName;
    /*let response = await profileService.downloadDocumentRequest(
      url,
      bearerToken
    );*/
    jwtInterceptor
    .get(url)
    .then((response) => {
      showMessage(response.data, "success");     
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <EnhancedTable
        title="Documents"
        head={headCells}
        rows={employeeDocuments}
        isAddable
        onAddClick={() => setOpen(true)}
        loading={loading}
      />
      <BaseModal
        open={open}
        handleClose={() => setOpen(false)}
        onSave={() => UploadDocuments_Click()}
        title="My Portal - Upload New Document"
      >
        <Grid container spacing="20px" px="10%">
          <Grid item xs={12}>
            <Typography variant="SmallBody">{t("Attachement")}</Typography>

            <DragAndDrop
              edit={true}
              allowMultiple
              onChangeFile={(e) => setFileInformation(e.target.files)}
            />
          </Grid>
        </Grid>
      </BaseModal>
      <BaseModal
        open={deleteModal}
        handleClose={() => setDeleteModal((pre) => !pre)}
        title="Delete Document"
        yesOrNo={true}
        onSave={() => handleDelete()}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="SmallBody">
              {t("Do you want to delete the selected document ?")}
            </Typography>
          </Grid>
        </Grid>
      </BaseModal>
    </>
  );
}

export default Documents;
