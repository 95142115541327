import axios from "axios";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL;
const API_URL2 = process.env.REACT_APP_API_LEAVE_SERVICE_URL;
//console.log("API_URL: "+API_URL);
const jwtInterceoptor = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "text/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: "",
  },
});

jwtInterceoptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(sessionStorage.getItem("token"));
  config.headers.Authorization = " Bearer " + tokensData.token;
  return config;
});

jwtInterceoptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = JSON.parse(sessionStorage.getItem("token"));

      //const barrerToken = sessionStorage.getItem("token_key");
      //const empId = sessionStorage.getItem("empId_key");
      const payload = {
        email: authData.employeedetail.email,
        refreshToken: authData.refreshToken,
      };

      let apiResponse = await axios.post(
        API_URL + "api/Authenticate/RefreshToken",
        payload
      );
      if (apiResponse.data.status && apiResponse.data.status != "Error")
        sessionStorage.setItem("token", JSON.stringify(apiResponse.data));

      error.config.headers.Authorization = " Bearer " + apiResponse.data.token;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);
export default jwtInterceoptor;


const jwtLeave = axios.create({
  baseURL: API_URL2,
  headers: {
    "Content-Type": "text/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: "",
  },
});

jwtLeave.interceptors.request.use((config) => {
  let tokensData = JSON.parse(sessionStorage.getItem("token"));
  config.headers.Authorization = " Bearer " + tokensData.token;
  return config;
});

jwtLeave.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = JSON.parse(sessionStorage.getItem("token"));

      //const barrerToken = sessionStorage.getItem("token_key");
      //const empId = sessionStorage.getItem("empId_key");
      const payload = {
        email: authData.employeedetail.email,
        refreshToken: authData.refreshToken,
      };

      let apiResponse = await axios.post(
        API_URL + "api/Authenticate/RefreshToken",
        payload
      );
      if (apiResponse.data.status && apiResponse.data.status != "Error")
        sessionStorage.setItem("token", JSON.stringify(apiResponse.data));

      error.config.headers.Authorization = " Bearer " + apiResponse.data.token;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export { jwtLeave };
