import { Stack, Typography } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

function LeavesInfo() {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
      <Stack direction="row" alignItems="center" gap={1} my={4}>
        <CircleIcon color="error" fontSize="10px" />
        <Typography variant="verySmallBody">{t("Bank Holiday")}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <CircleIcon color="success" fontSize="10px" />
        <Typography variant="verySmallBody">{t("Leaves taken")}</Typography>
      </Stack>
    </Stack>
  );
}

export default LeavesInfo;
