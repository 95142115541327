import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  PopoverItem,
  PopoverItemInfoContainer,
} from "../Navigation/Topbar/Notifications/Notifications.styles";
import BasicTabs from "../Global/BasicTabs";
import { RedInquiryIcon, RedMailIcon } from "../../assets/images";
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NOTIFICATIONS = [
  {
    id: 1,
    name: "Today",
    options: [
      {
        id: 1,
        name: "[HR community] response from @john",
        icon: RedMailIcon,
        time: "2 hours ago",
      },
      {
        id: 2,
        name: "Update HR module ",
        icon: RedInquiryIcon,
        time: "2 hours ago",
      },
    ],
  },
  {
    id: 2,
    name: "02 Janvier, 2022",
    options: [
      {
        id: 1,
        name: "Update of Slaeb’s T&C",
        icon: RedMailIcon,
        time: "2 hours ago",
      },
      {
        id: 2,
        name: " [CRM community] New post",
        icon: RedInquiryIcon,
        time: "2 hours ago",
      },
    ],
  },
];

function Leaves(props) {
  const { t } = useTranslation();

  if (props.NOTIFICATIONS.length === 0) {
    return (
      <Box>
        <Typography variant="extraSmallBody">No Notifications</Typography>
      </Box>
    );
  }

  return (
    <>
      {props.NOTIFICATIONS.map((item) => (
        <Box key={item.id}>
          <Typography variant="extraSmallBody">{t(item.name)}</Typography>
          <Box>
            {item.options.map((option) => (
              <PopoverItem
                key={option.id}
                sx={(theme) => ({
                  "&:hover": {
                    color: theme.palette.text.hover,
                  },
                })}
              >
                <img src={option.icon} alt="icon" />
                <PopoverItemInfoContainer>
                  <Typography variant="body">{t(option.name)}</Typography>
                  <Typography variant="extraSmallBody">
                    {t(option.time)}
                  </Typography>
                </PopoverItemInfoContainer>
              </PopoverItem>
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
}

function NotificationsPanel() {
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const [goalsNotifications, setgoalsNotifications] = useState(null);
  const [leaveNotifications, setleaveNotifications] = useState(null);
  const [payslipsNotifications, setpayslipsNotifications] = useState(null);

  const [loading, setLoading] = useState(false);
  const initialized = useRef(false);

  const navigate = useNavigate();

  const GetEmployeeNotifications = async () => {
    setLoading(true);

    jwtInterceptor
      .get(
        "api/EmployeeNotification/GetNotificationListByEmployeeDetailID?EmployeeDetailId=" +
          empId
      )
      .then((response) => {
        let allLeaves = [];
        let allGoals = [];
        let allPayslips = [];
        for (var x of response.data.goal) {
          let item = {
            id: x.identityId,
            name: x.date,
            options: [],
          };

          let options = [];

          for (var option of x.notifications) {
            let optionItem = {
              id: option.id,
              name: option.description,
              icon: RedMailIcon,
              time: "",
            };
            options.push(optionItem);
          }
          item.options = options;
          allGoals.push(item);
        }

        for (var x of response.data.leaves) {
          let item = {
            id: x.identityId,
            name: x.date,
            options: [],
          };

          let options = [];

          for (var option of x.notifications) {
            let optionItem = {
              id: option.id,
              name: option.description,
              icon: RedMailIcon,
              time: "",
            };
            options.push(optionItem);
          }
          item.options = options;
          allLeaves.push(item);
        }

        for (var x of response.data.payroll) {
          let item = {
            id: x.identityId,
            name: x.date,
            options: [],
          };

          let options = [];

          for (var option of x.notifications) {
            let optionItem = {
              id: option.id,
              name: option.description,
              icon: RedMailIcon,
              time: "",
            };
            options.push(optionItem);
          }
          item.options = options;
          allPayslips.push(item);
        }

        //
        const goalsData = <Leaves NOTIFICATIONS={allGoals} />;
        const LeavesData = <Leaves NOTIFICATIONS={allLeaves} />;
        const payslipsData = <Leaves NOTIFICATIONS={allPayslips} />;

        setgoalsNotifications(goalsData);
        setleaveNotifications(LeavesData);
        setpayslipsNotifications(payslipsData);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetEmployeeNotifications();
      } else {
        navigate("/login");
      }
    }
  }, []);

  //const LeavesData = <Leaves NOTIFICATIONS={NOTIFICATIONS}/>;

  return (
    <Box>
      <BasicTabs
        tabs={["Leaves", "Payslips"]}
        tabPanels={[
          leaveNotifications,
          // goalsNotifications,
          payslipsNotifications,
        ]}
        sx={{
          ".MuiTabs-flexContainer": {
            justifyContent: "start",
          },
        }}
      />
    </Box>
  );
}

export default NotificationsPanel;
