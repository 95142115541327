export const mockPieChartData = [
  {
    id: "male",
    label: "male",
    value: 5,
    color: "#37D310",
  },
  {
    id: "female",
    label: "female",
    value: 10,
    color: "#964CF5",
  },
];
