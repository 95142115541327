import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./slides.css";

import { Navigation, Pagination } from "swiper";
const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL;

export default function CompanySLides({ slides, setSlide }) {
  const getUrl = (item) => {
    return (
      API_URL +
      "api/PresentationDetail/OpenPresentationDetailFile/" +
      item.companyDetailId +
      "/" +
      item?.fileName?.split(",")[0]
    );
  };
  const handleView = async (item) => {
    console.log("TEST HANDLE VIEW ", item?.fileName?.split(","));

    let url =
      API_URL +
      "api/PresentationDetail/OpenPresentationDetailFile/" +
      item.companyDetailId +
      "/" +
      item?.fileName?.split(",")[0];
    window.open(url, "_blank");
  };
  return (
    <>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        navigation={true}
        pagination={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        onSlideChange={(swiper) => {
          setSlide(swiper.realIndex);
        }}
      >
        {slides.length > 0 &&
          slides
            .filter((item) => !!item?.fileName)
            .map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={getUrl(item)}
                  alt={item.fileName}
                  title={item.fileName}
                  onClick={() => {
                    handleView(item);
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  );
}
