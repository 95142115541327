import React, { useEffect, useState, useRef } from "react";
import EnhancedTable from "../../../Global/Table";
import { RoundedChip } from "../../../Global/Chips";
import jwtInterceptor from "../../../../services/interceptors";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../theme";

const headCells = [
  {
    id: "Equipment",
    label: "Equipment",
  },
  {
    id: "Brand",
    label: "Brand",
  },
  {
    id: "Model",
    label: "Model",
  },
  {
    id: "Registration",
    label: "Registration",
  },
  {
    id: "Status",
    label: "Status",
  },
  {
    id: "Comment",
    label: "Comment",
  },
];

function createData(Equipment, Brand, Model, Registration, Status, Comment) {
  return {
    Equipment,
    Brand,
    Model,
    Registration,
    Status,
    Comment,
  };
}

function Assets() {
  //const [open, setOpen] = useState(false);
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const [assets, setAssetState] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const tblRows = [];

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetAssetsListData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const GetAssetsListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/Employee/GetAssetList?EmployeeDetailId=" + empId)
      .then((response) => {
        for (var x of response.data) {
          let status = x.isAssetAssigned ? "Assigned" : "Not Assigned";
          console.log("STATUS", status);
          tblRows.push(
            createData(
              x.equipment,
              x.brand,
              x.model,
              x.registration,
              <RoundedChip
                status={status}
                color={status === "Assigned" ? Colors.success : Colors.error}
              />,
              x.comment
            )
          );
        }
        setAssetState(tblRows);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <EnhancedTable
        title="Assets"
        head={headCells}
        rows={assets}
        loading={loading}
      />
    </>
  );
}

export default Assets;
