import React from "react";
import { Stack } from "@mui/material";
import StatBox from "../Global/StatBox";
import { Colors } from "../../theme";

function KPIS(props) {
  return (
    <Stack direction="row" rowGap={2} columnGap={2} flexWrap="wrap">
      {props.kpis.map((kpi, i) => (
        <StatBox
          {...kpi}
          key={i}
          sx={{
            backgroundColor: Colors.backLessOps,
          }}
        />
      ))}
    </Stack>
  );
}

export default KPIS;
