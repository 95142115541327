import React, { useState, useRef } from "react";
import EnhancedTable from "../../Global/Table";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";

import { Box, Button, Grid, Typography, alpha } from "@mui/material";
import Download from "@mui/icons-material/Download";
import BaseModal from "../../Global/Modal";
import { useEffect } from "react";
import * as entity from "./Entity";
import * as payslipService from "../../../services/payslipService";
import DeleteIcon from "@mui/icons-material/Delete";
import DragAndDrop from "../../Global/DragAndDrop";
import UploadFiles from "../../Upload/upload-files.component";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../Global/WithSnackbar";
import jwtInterceptor from "../../../services/interceptors";
const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL + "/api/";

function createData(employeeId, employeeFullName, month, year, Action) {
  return {
    employeeId,
    employeeFullName,
    //title,
    month,
    year,
    Action,
  };
}
function CellAction({ id, filename, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        onClick={() => {
          handleDownload(id, filename);
        }}
        title="Download Payslip"
        sx={{
          backgroundColor: alpha("#18A0FB", 0.1),
          svg: {
            fill: "#18A0FB",
          },
        }}
      >
        <Download />
      </Button>
      <Button
        onClick={() => {
          handleView(id, filename);
        }}
        title="View Payslip"
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),
          svg: {
            fill: "#27AE60",
          },
        }}
      >
        <RemoveRedEye />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        title="Delete Payslip"
        onClick={() => {
          onDelete(id);
        }}
      >
        <DeleteIcon />
      </Button>
    </Box>
  );
}
const handleView = async (id, filename) => {
  let url = API_URL + "Payslip/OpenPayslip?id=" + id;
  window.open(url, "_blank");
};
const handleDownload = async (id, filename) => {
  let url = API_URL + "Payslip/DownloadPayslip?id=" + id;
  console.log(url);
  fetch(url, {
    method: "Get",
    headers: { "Content-Type": "application/pdf" },
  })
    .then((response) => response.blob())
    .then((response) => {
      // create "a" HTML element with href to file & click
      const href = URL.createObjectURL(response);
      console.log(href);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

function EmployeeSkillsTable(props) {
  const [open, setOpen] = useState(false);
  const [addButton, setaddButton] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedpayslip, setSelectedPayslip] = useState(0);
  const [payslips, setPayslips] = useState([]);
  const [files, setFiles] = useState([]);
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);

  const tblRows = [];
  const handleDelete = async () => {
    let url = "api/Payslip/DeletePayslip?id=" + selectedpayslip;
    //let response = await payslipService.deletePayslip(url);

    jwtInterceptor.delete(url).then((response) => {
      fetchPayloads();
    });
    setDeleteModal(false);
  };

  const fetchPayloads = async () => {
    let url = "api/Payslip/GetPayslips";
    console.log(props.empid);
    setLoading(true);
    if (props.empid) {
      setaddButton(false);
      url = "api/Payslip/GetPayslip?id=" + props.empid;
      console.log(url);
    }
    //let response = await payslipService.getPayslips(url);

    jwtInterceptor
      .get(url)
      .then((response) => {
        for (var x of response.data) {
          const filename =
            x.employeeId + "-" + x.month + "-" + x.year + x.filepath;
          tblRows.push(
            createData(
              x.employeeId,
              x.employeeName,
              //x.title,
              x.month,
              x.year,
              <CellAction
                id={x.payslipId}
                filename={filename}
                onDelete={(id) => {
                  setSelectedPayslip(id);
                  setDeleteModal(true);
                }}
              />
            )
          );
        }
        setPayslips(tblRows);
      })
      .catch((e) => {})
      .finally((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchPayloads();
    }
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <EnhancedTable
        title="Employees Payslips"
        head={entity.headCells}
        rows={payslips}
        onAddClick={() => setOpen((pre) => !pre)}
        isAddable={addButton}
        loading={loading}
      />
      <BaseModal
        open={open}
        handleClose={() => setOpen((pre) => !pre)}
        title="Upload new payslips"
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UploadFiles path="Payslip/UploadPayslip" />
          </Grid>
        </Grid>
      </BaseModal>
      <BaseModal
        open={deleteModal}
        handleClose={() => setDeleteModal((pre) => !pre)}
        title="Delete payslip"
        yesOrNo={true}
        onSave={() => handleDelete()}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="SmallBody">
              {t("Do you want to delete the selected payslip ?")}
            </Typography>
          </Grid>
        </Grid>
      </BaseModal>
    </>
  );
}

export default EmployeeSkillsTable;
