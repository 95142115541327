import React, { useEffect, useState } from "react";
import EnhancedTable from "../Global/Table";
import AddNewDepartment from "./AddNewDepartment";
import { Box, Button, alpha } from "@mui/material";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import jwtInterceoptor from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";
import DeleteModal from "../Global/DeleteModal";

function createData2(department, costCenter, onEdit, onDelete, id) {
  return {
    department,
    costCenter,
    Action: <CellAction onEdit={onEdit} onDelete={onDelete} id={id} />,
  };
}
function CellAction({ onEdit = () => {}, onDelete = () => {}, id }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
        onClick={() => onEdit(id)}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={() => onDelete(id)}
      >
        <DeleteIcon />
      </Button>
      {/* <Button>
        <MoreVertIcon />
      </Button> */}
    </Box>
  );
}

function DepartmentsTable() {
  const [newDapartment, setNewDapartment] = useState({
    open: false,
    id: null,
  });
  const [DeleteDepartmentMaster, setDeleteDepartmentMaster] = useState({
    open: false,
    id: null,
  });

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();

  const getDepartments = () => {
    setLoading(true);
    jwtInterceoptor
      .get("api/DepartmentMaster/GetAllDepartmentMasterList")
      .then((res) => {
        setDepartments(
          res.data.map((item) => ({
            id: item.departmentId,
            department: item.department,
            costCenter: item.costCenter,
          }))
        );
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const deletDepartment = () =>
    jwtInterceoptor
      .delete(
        `api/DepartmentMaster/DeleteDepartmentMaster?DepartmentId=${DeleteDepartmentMaster.id}`
      )
      .then((res) => {
        showMessage("Department Information Deleted Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => {
        setDeleteDepartmentMaster({ open: false, id: null });
        getDepartments();
      });

  const updateDepartment = (data) =>
    jwtInterceoptor
      .post("api/DepartmentMaster/UpdateDepartmentMaster", {
        departmentId: data.id,
        department: data.department,
        costCenter: data.costCenter,
      })
      .then((res) => {
        showMessage("Department Information Updated Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => {
        getDepartments();
      });

  const addDepartment = (data) =>
    jwtInterceoptor
      .post("api/DepartmentMaster/CreateDepartmentMaster", {
        department: data.department,
        costCenter: data.costCenter,
      })
      .then((res) => {
        showMessage("Department record added successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => {
        getDepartments();
      });

  const onEdit = (id) => {
    setNewDapartment({ open: true, id });
  };

  const onDelete = (id) => {
    setDeleteDepartmentMaster({ open: true, id });
  };

  return (
    <>
      <EnhancedTable
        head={[
          {
            id: "department",
            label: "Department",
          },
          {
            id: "costCenter",
            label: "Cost Center",
          },
          {
            id: "action",
            label: "Actions",
          },
        ]}
        rows={departments.map((department) =>
          createData2(
            department.department,
            department.costCenter,
            onEdit,
            onDelete,
            department.id
          )
        )}
        isAddable={true}
        sx={{
          minWidth: "100%",
        }}
        loading={loading}
        onAddClick={() =>
          setNewDapartment({
            open: true,
            id: null,
          })
        }
      />
      <AddNewDepartment
        open={newDapartment.open}
        handleClose={() => setNewDapartment(false)}
        handleSave={newDapartment.id ? updateDepartment : addDepartment}
        department={
          departments.find((item) => item.id === newDapartment.id) || {}
        }
        title={
          newDapartment.id
            ? "Update Department information"
            : "Add new department"
        }
      />
      <DeleteModal
        message={"Are you sure you want to delete this department?"}
        title={"Delete Department"}
        onCancel={() => setDeleteDepartmentMaster({ open: false, id: null })}
        onConfirm={() => deletDepartment()}
        open={DeleteDepartmentMaster.open}
      />
    </>
  );
}

export default DepartmentsTable;
