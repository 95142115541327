import { ResponsivePie } from "@nivo/pie";
import { mockPieChartData } from "../../mock/chartsData";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const PieChart = ({ data }) => (
  <ResponsivePie
    data={data}
    innerRadius={0.85}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={0}
    borderWidth={1}
    colors={(d) => d.data.color} // Use color from data
    borderColor={{
      from: "color",
      modifiers: [["darker", "0"]],
    }}
    enableArcLinkLabels={false}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    enableArcLabels={false}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: "color",
      modifiers: [["darker", 2]],
    }}
    legends={[]}
  />
);

export default PieChart;
