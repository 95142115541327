import { Box, Typography } from "@mui/material";
import CircularIcon from "./CircularIcon";
import { useTranslation } from "react-i18next";

const styles = {
  statsContainer: (theme) => ({
    flex: 1,
    display: "flex",
    padding: "24px",
    maxWidth: "492px",
    border: `1px solid ${theme.palette.common.border}`,
    borderRadius: "20px",
    gap: "15px",
    alignItems: "center",

    "@media (max-width: 600px)": {
      padding: "10px",
    },
  }),
  statBox: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
};

const StatBox = ({ title, value, icon, color, sx = {} }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        ...styles.statsContainer(theme),
        ...sx,
        ...(value == 0 || value == "0" || value == "0%"
          ? {
              filter: "grayscale(100%)",
              opacity: 0.5,
            }
          : {}),
      })}
    >
      <CircularIcon icon={icon} color={color} />
      <Box sx={styles.statBox}>
        <Typography variant="h5">{t(value)}</Typography>
        <Typography
          variant="smallBody"
          sx={{
            opacity: 0.7,
          }}
        >
          {t(title)}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
