import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CompanySLides from "./CompanySlides";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "../Global/WithSnackbar";
import jwtInterceptor from "../../services/interceptors";
import { Link, useNavigate } from "react-router-dom";

function CompanyPresentation() {
  const { t } = useTranslation();
  const [presenation, setpresentaion] = useState([]);
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getCompanyPresentaionData();
      } else {
        navigate("/login");
      }
    }
  }, []);
  const getCompanyPresentaionData = async () => {
    jwtInterceptor
      .get("api/PresentationDetail/GetDetailsForPresentationPage")
      .then((response) => {
        setpresentaion(response.data);
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };

  return (
    <Grid
      container
      spacing={1}
      sx={(theme) => ({
        padding: "20px",
        backgroundColor: theme.palette.background.backLessOps,
        borderRadius: "20px",
      })}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h5" mb={2}>
          {t("Company Presentation")}
        </Typography>
        <Stack
          direction="column"
          justifyContent="space-between"
          gap={1}
          sx={{
            height: "80%",
          }}
        >
          {presenation.length > 0 && (
            <Typography
              variant="extraSmallBody"
              sx={{ lineHeight: "normal" }}
              mt={2}
            >
              {presenation[currentSlide].description}
            </Typography>
          )}
          <Link to="/presentations" style={{ color: "white", width: "90%" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                m: 0,
                px: "30px",
                mt: 1,
                width: "100%",
                mb: 2,
              }}
            >
              {t("More Details")}
            </Button>
          </Link>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          boxShadow: "0px 4px 8px 0px rgba(9, 44, 76, 0.10)",
        }}
      >
        <CompanySLides slides={presenation} setSlide={setCurrentSlide} />
      </Grid>
    </Grid>
  );
}

export default CompanyPresentation;
