import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { UserImage } from "../Navigation/Topbar/UserInfo/UserInfo";
import EditAndSave from "../Global/EditAndSave";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularIcon from "../Global/CircularIcon";
import { Colors } from "../../theme";
import { useTranslation } from "react-i18next";

function PresentationInfo({ onEditClick, selected, handleDelete,isManagerOrAdmin }) {
  const { t } = useTranslation();
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          sx={{
            color: "primary.main",
            fontWeight: "bold",
          }}
        >
          {selected.title}
        </Typography>
        <EditAndSave showConfirm={false} edit={false} setEdit={onEditClick} isManagerOrAdmin={isManagerOrAdmin} />
        <CircularIcon
          icon={<DeleteIcon />}
          color={Colors.error}
          sx={{
            marginLeft: "10px",
            display:isManagerOrAdmin,
          }}
          onClick={handleDelete}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.common.border}`,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <UserImage userPicture={selected?.user?.img} />
          <Typography variant="smallBodyBold">
            {selected?.user?.name}
          </Typography>
        </Stack>
        <div>
          <Typography variant="smallBodyBold">{t("Posted")}:</Typography>
          <Typography variant="smallBody" ml={1}>
            {selected.posted}
          </Typography>
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography variant="h6">{t("Summary")}</Typography>
        <div>
          <Typography variant="smallBodyBold">{t("Format")}:</Typography>
          <Typography variant="smallBody" ml={1}>
            {selected.format}
          </Typography>
        </div>
      </Stack>
      <Typography variant="body">{selected.description}</Typography>
    </Box>
  );
}

export default PresentationInfo;
