import React, { useEffect, useState, useRef, useContext } from "react";
import { SupportIcon, logo } from "../../../assets/images";
import { Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import MenuItem from "./MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";
import { themeContext } from "../../../theme";

function Sidebar({ menuItems }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(0);

  const theme = useTheme();

  const roles = sessionStorage.getItem("roles");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openTooltip, setOpenTooltip] = useState(0);
  const { myTheme } = useContext(themeContext);

  // get current path
  const { pathname } = useLocation();

  const [favs, setFavs] = useState([]);
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getFavsData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const getFavsData = async () => {
    jwtInterceptor
      .get(
        "api/FavouriteLink/GetFavouriteLinksByEmployeedetaiLId?EmployeeDetailId=" +
          empId
      )
      .then((response) => {
        let allFavs = [];
        for (var x of response.data) {
          let item = {
            id: x.favouriteLinkId,
            name: x.title,
            URL: x.favouriteLinkurl,
          };
          allFavs.push(item);
        }
        setFavs(allFavs);
      });
  };

  const onFavCreate = async (fav) => {
    let url = "api/FavouriteLink/AddToFavourite";
    jwtInterceptor.post(url, fav).then((response) => {
      showMessage(response.data, "success");
      getFavsData();
    });
  };
  const onFavDelete = async (favId) => {
    let url = "api/FavouriteLink/RemoveFromFavourite?FavouriteLinkId=" + favId;
    jwtInterceptor.post(url).then((response) => {
      showMessage(response.data, "success");
      getFavsData();
    });
  };

  const handleOpenTooltip = (id) => () => {
    if (isMobile) {
      setOpenTooltip(id);
    }
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(0);
  };

  const handleAddFav = (name, route, e) => {
    e.stopPropagation();
    e.preventDefault();

    let item = {
      favouriteLinkurl: route,
      employeeDetailId: empId,
      title: name,
    };

    if (!favs.some((fav) => fav.name === name)) {
      setFavs((pre) => [...pre, { id: pre.length + 1, name }]);
      onFavCreate(item);
    } else {
      let item = favs.filter((fav) => fav.name === name);
      setFavs((pre) => pre.filter((fav) => fav.name !== name));
      onFavDelete(item[0].id);
    }
  };

  const isFavorite = (name) => {
    return favs.some((fav) => fav.name === name);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Box
        sx={{
          backgroundColor:
            pathname === "/" ? "transparent" : myTheme.sidebar.background,
          padding: pathname !== "/" ? "20px" : "",
          "@media (max-width: 600px)": {
            padding: pathname !== "/" ? "10px" : "",
          },
        }}
      >
        {pathname !== "/" && (
          <Box mt={1}>
            <Link style={{ width: "fit-content" }}>
              <img
                src={menuOpen ? myTheme.logo : myTheme.logoIcon}
                alt="logo"
                style={{
                  ...(menuOpen
                    ? {
                        width: "150px",
                        height: "auto",
                      }
                    : {}),
                  ...(!menuOpen
                    ? isMobile
                      ? {
                          height: "20px",
                          marginLeft: "5px",
                          width: "fit-content",
                        }
                      : {
                          height: "35px",
                          marginLeft: "8px",
                          width: "fit-content",
                        }
                    : {}),
                }}
              />
            </Link>
          </Box>
        )}
        <Box
          sx={{
            mt: 3,
            height: "calc(100vh - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            overflow: "auto",
            gap: "30px",
            "&::-webkit-scrollbar": {
              display: "none",
            },

            scrollbarWidth: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            {menuItems.map((option, i) => {
              if (roles.includes(option.role)) {
                return (
                  <MenuItem
                    icon={option.icon}
                    name={option.name}
                    key={option.id}
                    menuOpen={menuOpen}
                    subOptions={option.subOptions}
                    setMenuOpen={setMenuOpen}
                    id={option.id}
                    openSubMenu={openSubMenu}
                    setOpenSubMenu={setOpenSubMenu}
                    //route={option?.subOptions?.length < 1 ? option.route : ""}
                    route={option.route}
                    handleOpenTooltip={handleOpenTooltip}
                    handleCloseTooltip={handleCloseTooltip}
                    openTooltip={openTooltip}
                    isMobile={isMobile}
                    handleAddFavorite={handleAddFav}
                    isFavorite={isFavorite}
                    myTheme={myTheme}
                  />
                );
              } else {
                return null;
              }
            })}
          </Box>
          {menuItems.length > 0 && roles.includes("Admin") && (
            <MenuItem
              icon={SupportIcon}
              name="Admin settings"
              menuOpen={menuOpen}
              id="-1"
              route={"/admin-settings"}
              handleOpenTooltip={handleOpenTooltip}
              handleCloseTooltip={handleCloseTooltip}
              openTooltip={openTooltip}
              isMobile={isMobile}
              handleAddFavorite={handleAddFav}
              isFavorite={isFavorite}
              myTheme={myTheme}
            />
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default Sidebar;
