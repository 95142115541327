import { Box, Button, Grid, alpha } from "@mui/material";
import React, { useEffect, useState } from "react";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedTable from "../Global/Table";
import { CircularChip } from "../Global/Chips";
import AddNewSkill from "./AddNewSkill";
import InfoCards from "./InfoCards";
import jwtInterceoptor from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";
import DeleteModal from "../Global/DeleteModal";
import SkillTypeSetting from "./SkillTypeSetting";
import { useTranslation } from "react-i18next";

function CellAction({ id, onEdit, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
        onClick={() => onEdit(id)}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={() => onDelete(id)}
      >
        <DeleteIcon />
      </Button>
      {/* <Button>
        <MoreVertIcon />
      </Button> */}
    </Box>
  );
}

function createData(skill, skillLevel, skillScore, onEdit, id, onDelete) {
  return {
    skill,
    skillLevel,
    skillScore,
    Action: <CellAction id={id} onEdit={onEdit} onDelete={onDelete} />,
  };
}

function EmployeeInfo() {
  const [newSkill, setNewSkill] = useState({
    open: false,
    id: null,
  });
  const [skillExpertiseList, setSkillExpertiseList] = useState([]);
  const [skillAchievementList, setSkillAchievementList] = useState([]);
  const [SkillConfiguration, setSkillConfiguration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });

  const onEdit = (id) => {
    setNewSkill({
      open: true,
      id,
    });
  };

  const getExpertise = () => {
    setLoading(true);
    jwtInterceoptor
      .get("api/SkillConfiguration/GetSkillExpertiseList")
      .then((res) => {
        setSkillExpertiseList(res.data);
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSkillsConfiguration = () => {
    setTableLoading(true);
    jwtInterceoptor
      .get("api/SkillConfiguration/GetSkillConfigurationList")
      .then((res) => {
        setSkillConfiguration(res.data);
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getSkillAchievement = () =>
    jwtInterceoptor
      .get("api/SkillConfiguration/GetSkillAchievementList")
      .then((res) => {
        setSkillAchievementList(res.data);
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const updateAchievement = (data) =>
    jwtInterceoptor
      .post("api/SkillConfiguration/UpdateSkillAchievement", {
        skillAchievementId: data.id,
        score: data.value,
        description: data.title,
      })
      .then((res) => {
        showMessage("Skill Achievement Updated Successfully", "success");
        getSkillAchievement();
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const updateExpertise = (data) =>
    jwtInterceoptor
      .post("api/SkillConfiguration/UpdateSkillExpertise", {
        skillExpertiseId: data.id,
        expertise: data.value,
      })
      .then((res) => {
        showMessage("Skill Expertise Updated Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  useEffect(() => {
    getExpertise();
    getSkillAchievement();
    getSkillsConfiguration();
    getSkillTypeConfig();
  }, []);

  const onExpertiseSave = async ({ updatedFields }) => {
    if (updatedFields.length > 0) {
      await updatedFields.forEach((item) => {
        return updateExpertise(item);
      });
    }
    getExpertise();
  };

  const onAchievementSave = async ({ updatedFields }) => {
    if (updatedFields.length > 0) {
      await updatedFields.forEach((item) => {
        return updateAchievement(item);
      });
    }
    getSkillAchievement();
  };

  const addNewSkill = (data) =>
    jwtInterceoptor
      .post("api/SkillConfiguration/CreateSkillConfigurations", {
        skill: data.skill,
        skillType: data.skillType,
        requiredSkillAchievementId: data.achievementScore,
      })
      .then((res) => {
        getSkillsConfiguration();
        showMessage("Skill Configuration Added Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const [skillTypes, setskillTypes] = useState([]);

  const getSkillTypeConfig = async () => {
    setLoading(true);
    jwtInterceoptor
      .get("api/SkillConfiguration/GetAllSkillTypeDetailList")
      .then((res) => {
        setskillTypes(res.data);
      });
  };

  const updateSkill = (data) =>
    jwtInterceoptor
      .post("api/SkillConfiguration/UpdateSkillConfiguration", {
        skillConfigurationId: data.id,
        skill: data.skill,
        skillType: data.skillType,
        requiredSkillAchievementId: data.achievementScore,
      })
      .then((res) => {
        getSkillsConfiguration();
        showMessage("Skill Configuration Updated Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const deleteSkill = (id) =>
    jwtInterceoptor
      .delete(
        `api/SkillConfiguration/DeleteSkillConfiguration?SkillConfigurationId=${id}`
      )
      .then((res) => {
        getSkillsConfiguration();
        showMessage("Skill Configuration Deleted Successfully", "success");
      })
      .catch((err) => {
        showMessage(err.response.data.Message, "error");
      });

  const onDeleteConfirm = async () => {
    await deleteSkill(deleteModal.id);
    getSkillsConfiguration();
    setDeleteModal({
      open: false,
      id: null,
    });
  };

  const onDelete = (id) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };

  const onAddNewSkill = async (data) => {
    if (data.id) {
      await updateSkill(data);
    } else {
      await addNewSkill(data);
    }
    getSkillsConfiguration();
    setNewSkill({
      open: false,
      id: null,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid container gap={3} p={2}>
        <Grid
          item
          xs={11.5}
          lg={5.6}
          className="section-border"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.common.border}`,
          })}
        >
          <h3>{t("Skills Settings")}</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <InfoCards
                values={skillExpertiseList.map((item) => ({
                  id: item.skillExpertiseId,
                  value: item.expertise,
                }))}
                title={"Expertise"}
                loading={loading}
                onSave={onExpertiseSave}
                addAndDelete={false}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <InfoCards
                twoTier
                title2="Score Description"
                title={"Score"}
                values={skillAchievementList.map((item) => ({
                  id: item.skillAchievementId,
                  value: item.score,
                  title: item.description,
                }))}
                addAndDelete={false}
                onSave={onAchievementSave}
                loading={loading}
                numberLimit={5}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6.1}
          className="section-border"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.common.border}`,
          })}
        >
          <Grid
            sx={(theme) => ({
              border: `1px solid ${theme.palette.common.border}`,
              padding: "5px",
              borderRadius: "10px",
              marginTop: "2px",
            })}
          >
            <EnhancedTable
              head={[
                {
                  id: "skill",
                  label: "Skill",
                },
                {
                  id: "skillType",
                  label: "Skill Type",
                },
                {
                  id: "levelRequired",
                  label: "Level Required",
                },
                {
                  id: "action",
                  label: "Actions",
                },
              ]}
              rows={SkillConfiguration.map((item) =>
                createData(
                  item.skill,
                  item.skillType,
                  <CircularChip value={item.requiredScore} />,
                  onEdit,
                  item.skillConfigurationId,
                  onDelete
                )
              )}
              isAddable={true}
              sx={{
                minWidth: "100%",
              }}
              loading={tableLoading}
              onAddClick={() =>
                setNewSkill({
                  open: true,
                  id: null,
                })
              }
            />
          </Grid>
          <SkillTypeSetting />
        </Grid>
      </Grid>
      <AddNewSkill
        open={newSkill.open}
        handleClose={() =>
          setNewSkill({
            open: false,
            id: null,
          })
        }
        skillTypes={skillTypes}
        handleSave={onAddNewSkill}
        skillAchievementList={skillAchievementList}
        skill={() => {
          const skill = SkillConfiguration.find(
            (item) => item.skillConfigurationId === newSkill.id
          );

          return {
            skill: skill?.skill,
            skillType: skill?.skillType,
            achievementScore: skill?.requiredSkillAchievementId,
            id: skill?.skillConfigurationId,
          };
        }}
      />
      <DeleteModal
        message={"Are you sure you want to delete this skill?"}
        title={"Delete Skill"}
        onCancel={() => setDeleteModal({ open: false, id: null })}
        onConfirm={onDeleteConfirm}
        open={deleteModal.open}
      />
    </>
  );
}

export default EmployeeInfo;
