import { Box, IconButton, Typography, alpha } from "@mui/material";
import React from "react";
import {
  CollapseIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
} from "../../../assets/images";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuTooltip from "../../Global/MenuTooltip";

const backColor = "#18A0FB";

function MenuItem({
  icon,
  name,
  menuOpen = false,
  subOptions = [],
  setMenuOpen,
  openSubMenu,
  setOpenSubMenu,
  id,
  route,
  handleOpenTooltip,
  handleCloseTooltip,
  openTooltip,
  isMobile,
  isFavorite,
  handleAddFavorite,
  myTheme,
}) {
  const open = openSubMenu === id;
  const { t } = useTranslation();

  const handleOpen = () => {
    if (isMobile) setMenuOpen(false);
    setOpenSubMenu(id);
  };
  const handleClose = () => setOpenSubMenu(id);

  const handleCloseMenu = () => {
    handleClose();
    setMenuOpen((pre) => !pre);
  };

  const toolTipBack =
    myTheme.sidebar.background === "transparent"
      ? backColor
      : myTheme.sidebar.background;

  const MenuItem = ({ subOption }) => {
    const { t } = useTranslation();
    return (
      <Link to={subOption.route}>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "9px 11px",
            borderRadius: "10px",
            cursor: "pointer",
            height: "45px",
            minWidth: "200px",
            "&:hover": {
              backgroundColor:
                toolTipBack !== backColor
                  ? menuOpen
                    ? theme.palette.background.default
                    : toolTipBack
                  : toolTipBack,
              color:
                toolTipBack !== backColor
                  ? menuOpen
                    ? "black"
                    : theme.palette.background.default
                  : theme.palette.background.default,
            },
          })}
        >
          <Typography variant="smallBody" className="child-text">
            {t(subOption.name)}
          </Typography>
          <IconButton
            onClick={(e) =>
              handleAddFavorite(subOption.name, subOption.route, e)
            }
          >
            <StarRoundedIcon
              color={isFavorite(subOption.name) ? "iconFocus" : "iconColor"}
            />
          </IconButton>
        </Box>
      </Link>
    );
  };

  const MenuItems = () => (
    <>
      {subOptions.map((subOption) => (
        <MenuItem subOption={subOption} key={subOption.id} />
      ))}
    </>
  );

  return (
    <Box
      sx={{
        ...(menuOpen && { minWidth: "250px" }),
        py: "10px",
        px: "5px",
        borderRadius: "10px",
        color: myTheme.sidebar.color,

        "&:hover": {
          backgroundColor: myTheme.sidebar.background2,
          color: myTheme.sidebar.colorDark,

          img: {
            ...(myTheme.isWhite
              ? {
                  filter: "brightness(100%) invert(0%)",
                }
              : {}),
          },
        },

        img: {
          ...(myTheme.isWhite
            ? {
                filter: "brightness(0%) invert(100%)",
              }
            : {}),
        },

        ...(open
          ? menuOpen && {
              "&:hover": {
                backgroundColor: "transparent",
              },
            }
          : {}),
      }}
    >
      <MenuTooltip
        menu={
          subOptions.length > 0 ? (
            <MenuItems />
          ) : (
            <MenuItem
              subOption={{
                name: t(name),
                route: route,
                isFavorite: false,
              }}
            />
          )
        }
        inTooltip={isMobile ? true : !menuOpen && !open}
        isMobile={isMobile}
        open={openTooltip === id}
        handleOpenTooltip={handleOpenTooltip(id)}
        handleCloseTooltip={handleCloseTooltip}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            cursor: "pointer",

            img: {
              width: menuOpen ? "20px" : "27px",

              "@media (max-width: 600px)": {
                width: menuOpen ? "15px" : "20px",
              },
            },
          }}
        >
          <img src={icon} alt="logo" />
          <Box
            sx={{
              flex: 1,
              display: menuOpen ? "flex" : "none",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to={route}>
              <Typography variant="body" color="inherit">
                {t(name)}
              </Typography>
            </Link>
            {subOptions.length > 0 &&
              (open ? (
                <ExpandLessIcon onClick={handleClose} />
              ) : (
                <ExpandMoreIcon onClick={handleOpen} />
              ))}
          </Box>
          {name === "Home" && !isMobile && (
            <img
              src={CollapseIcon}
              alt="collapseIcon"
              onClick={handleCloseMenu}
            />
          )}
        </Box>
      </MenuTooltip>
      {menuOpen && open && (
        <Box
          sx={(theme) => ({
            borderLeft: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
            paddingLeft: "15px",
            marginLeft: "7px",
          })}
          className="menu-items"
        >
          <MenuItems />
        </Box>
      )}
    </Box>
  );
}

export default MenuItem;
