import React from "react";
import BaseModal from "../../Global/Modal";
import Documents from "../../Profile/MyProfile/ProfileOptions/Documents";

function ViewDocModal({ open, handleClose, title, onSave, selectedId }) {
  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={title}
      onSave={onSave}
      sx={{
        maxWidth: "fit-content",
      }}
      showSaveButton={false}
    >
      {selectedId && <Documents employeeId={selectedId} />}
    </BaseModal>
  );
}

export default ViewDocModal;
