import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CircularIcon from "../Global/CircularIcon";
import SchoolIcon from "@mui/icons-material/School";
import { useTranslation } from "react-i18next";

// const states = [
//   {
//     id: 1,
//     name: "Number of Expert",
//     value: 10,
//     color: "#964CF5",
//   },
//   {
//     id: 2,
//     name: "Number of  Advanced",
//     value: 7,
//     color: "#18A0FB",
//   },
//   {
//     id: 3,
//     name: "Number of Basic",
//     value: 2,
//     color: "#37D310",
//   },
//   {
//     id: 4,
//     name: "Number of Trainee",
//     value: 10,
//     color: "#964CF5",
//   },
// ];

function TraineeStats(props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={(theme) => ({
        p: "25px",
        borderRadius: "20px",
        border: `1px solid ${theme.palette.common.border}`,

        minWidth: { md: "490px", xs: "100%" },
      })}
    >
      {props.skills.map((state) => (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            py: "10px",
            borderBottom: "1px solid rgba(9, 44, 76, 0.10)",
          }}
          key={state.id}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularIcon
              color={state.color}
              icon={
                <SchoolIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              }
              sx={{ width: "40px", height: "40px" }}
            />
            <Typography
              variant="smallBody"
              sx={{
                opacity: 0.7,
              }}
            >
              {t(state.name)}
            </Typography>
          </Stack>
          <Typography variant="h5" fontWeight={600}>
            {t(state.value)}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}

export default TraineeStats;
