import { Box, Stack, Typography, alpha } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import PeopleIcon from "@mui/icons-material/People";
import StatBox from "../Global/StatBox";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import VerifiedIcon from "@mui/icons-material/Verified";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import DoneIcon from "@mui/icons-material/Done";
import SchoolIcon from "@mui/icons-material/School";
import TraineeStats from "./TraineeStats";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "../../services/interceptors";
import PieChart from "../Charts/PieChart";
import { preparePieChartData } from "../../utils/formaters";
import { useTranslation } from "react-i18next";

const styles = {
  statsContainer: {
    flex: 1,
    display: "flex",
    padding: "24px",
    maxWidth: "492px",
    border: "1px solid rgba(9, 44, 76, 0.1)",
    borderRadius: "20px",
    gap: "15px",
    alignItems: "center",

    "@media (max-width: 600px)": {
      padding: "10px",
    },
  },
  statIcon: {
    padding: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "start",
    svg: {
      color: "#18A0FB",
    },
    backgroundColor: alpha("#18A0FB", 0.1),
  },
  statBox: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
};

function Dashboard() {
  const [hrkpis, sethrkpis] = useState({});
  const [skills, setskills] = useState([]);
  const initialized = useRef(false);

  const bearerToken = sessionStorage.getItem("token_key");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const GetHRKPIsData = async () => {
    jwtInterceptor.get("api/HrDashboard/GetHrDashboard").then((response) => {
      sethrkpis((item) => ({
        ...hrkpis,
        ...response.data,
      }));
      //
      let skillsInfo = [];
      let item1 = {
        id: 1,
        name: "Number of Expert",
        value: response.data.noOfExpert,
        color: "#964CF5",
      };

      let item2 = {
        id: 2,
        name: "Number of  Advanced",
        value: response.data.noOfAdvanced,
        color: "#18A0FB",
      };
      let item3 = {
        id: 3,
        name: "Number of Basic",
        value: response.data.noOfBasic,
        color: "#37D310",
      };
      let item4 = {
        id: 4,
        name: "Number of Trainee",
        value: response.data.noOfTrainee,
        color: "#E01C63",
      };

      skillsInfo.push(item1);
      skillsInfo.push(item2);
      skillsInfo.push(item3);
      skillsInfo.push(item4);
      setskills(skillsInfo);
      //
    });
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetHRKPIsData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  return (
    <div>
      <Typography variant="h6">{t("Dashboard")}</Typography>
      <Box
        sx={(theme) => ({
          borderRadius: "20px",
          border: `1px solid ${theme.palette.common.border}`,
          padding: "12px 8px",
          width: "100%",
          maxWidth: "350px",
          mt: "20px",
          display: "flex",
          alignItems: "center",
          gap: {
            xs: "8px",
            md: "20px",
          },
          height: "200px",
        })}
      >
        <Box sx={styles.statIcon}>
          <PeopleIcon />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="smallBody"
              sx={{
                opacity: 0.7,
              }}
            >
              {t("Total number of Employee")}
            </Typography>
            <Typography variant="h5">{hrkpis.totalEmployees}</Typography>
          </Box>
          <Box
            sx={{
              mt: "28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: {
                xs: "10px",
                md: "30px",
              },
            }}
          >
            <Box
              sx={{
                height: "100px",
                width: "100px",
              }}
            >
              <PieChart
                data={preparePieChartData([
                  {
                    key: t("male"),
                    value: hrkpis.totalMaleEmployees || 0,
                    color: "#37D310",
                  },
                  {
                    key: t("female"),
                    value: hrkpis.totalFemaleEmployees || 0,
                    color: "#964CF5",
                  },
                ])}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    backgroundColor: "#37D310",
                  }}
                />
                <Typography variant="smallBody">{t("Male")}</Typography>
              </Box>
              <Box
                sx={{
                  mt: "15px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    backgroundColor: "#964CF5",
                  }}
                />
                <Typography variant="smallBody">{t("Female")}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: "20px",
          columnGap: "20px",
          mt: "30px",
        }}
      >
        <StatBox
          title="Total of skills registered"
          value={hrkpis.totalSkills}
          icon={<LogoutIcon />}
          color="#19C03E"
          sx={{ minWidth: { md: "450px", xs: "100%" } }}
        />
        <StatBox
          title="Certificates pending renewal"
          value={hrkpis.certificatesPendingRenewal}
          icon={<EmojiEventsIcon />}
          color="#18A0FB"
          sx={{ minWidth: { md: "450px", xs: "100%" } }}
        />
        <Stack
          direction="row"
          gap="20px"
          flexWrap="wrap"
          flex={1}
          alignItems="flex-start"
        >
          <StatBox
            title="New skills register"
            value={hrkpis.newSkillRegistered}
            icon={<VerifiedIcon />}
            color="#9DB604"
            sx={{ minWidth: { md: "450px", xs: "100%" } }}
          />
        </Stack>
        <TraineeStats skills={skills} />
      </Box>
    </div>
  );
}

export default Dashboard;
